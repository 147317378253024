import lazySizes from 'lazysizes';

const url = window.location.href;
const hasPrintSuffix = url.endsWith('?print=1');

const unveilImages = () => {
  document.querySelectorAll('img.js-lazyload').forEach((v) => {
    lazySizes.loader.unveil(v);
  });
};

if (hasPrintSuffix) {
  unveilImages();
}
