const anchorBars = document.querySelectorAll('.anchor-bar');
for (let i = 0; i < anchorBars.length; i += 1) {
  const actualAnchorBar = anchorBars[i];
  if (actualAnchorBar.className.indexOf('sticky') > 1) {
    if (actualAnchorBar.parentNode.nodeName !== 'main') {
      actualAnchorBar.parentNode.className += ' anchor-bar__sticky';
    }
    document.onscroll = function OnScroll() {
      if (document.querySelectorAll('.headroom').length > 0) {
        if (document.querySelectorAll('.headroom')[0].className.indexOf('headroom--pinned') > -1) {
          if (actualAnchorBar.className.indexOf('headroom--padding') < 0) {
            actualAnchorBar.className += ' headroom--padding';
          }
        } else {
          actualAnchorBar.className = actualAnchorBar.className.replace(/\bheadroom--padding\b/g, '');
        }
        if (actualAnchorBar.getBoundingClientRect().top > 0) {
          if (actualAnchorBar.className.indexOf('notsticky') < 0) {
            actualAnchorBar.className += ' notsticky';
          }
        } else {
          actualAnchorBar.className = actualAnchorBar.className.replace(/\bnotsticky\b/g, '');
        }
      }
    };
  }
  const anchorBarLinks = document.querySelectorAll('.anchor-bar__links__link');
  for (let j = 0; j < anchorBarLinks.length; j += 1) {
    const actualAnchorLink = anchorBarLinks[j];
    actualAnchorLink.addEventListener('click', (e) => {
      let ie = false;
      let scrollPos = 0;
      if ((window.navigator.userAgent.indexOf('MSIE ') > 0) || (window.navigator.userAgent.indexOf('Trident/') > 0) || (window.navigator.userAgent.indexOf('Edge/') > 0)) {
        ie = true;
      }

      e.preventDefault();
      const anchorBarLinkElems = actualAnchorLink.parentNode.querySelectorAll('.anchor-bar__links__link');
      for (let k = 0; k < anchorBarLinkElems.length; k += 1) {
        const actualElem = anchorBarLinkElems[k];
        actualElem.className = actualElem.className.replace(/\bactive\b/g, '');
      }
      actualAnchorLink.classList.toggle('active');
      const link = actualAnchorLink.getAttribute('href');
      const linkelem = document.getElementById(link.substr(1, link.length));
      if (linkelem) {
        if (actualAnchorLink.dataset.offset) {
          scrollPos = parseInt(linkelem.offsetTop - actualAnchorLink.dataset.offset, 10);
        } else {
          scrollPos = parseInt(linkelem.offsetTop, 10);
        }
        if (!ie) {
          window.scrollTo({
            top: scrollPos,
            behavior: 'smooth',
          });
        } else {
          window.scrollTo(0, scrollPos);
        }
      }
    });
  }
}
