// @flow
import * as React from 'react';
import format from 'string-template';
import gt from '../globals/translation';

export type Message = string;
export type MessagePlural = string;
export type Context = string;
export type Count = number;
export type Data = {[string]: string | number};

export type T = (Message, ?Data) => string;
export type TP = (Message, MessagePlural, Count, ?Data) => string;
export type TC = (Message, Context, ?Data) => string;
export type TCP = (Message, MessagePlural, Count, Context, ?Data) => string;

function getDisplayName(WrappedComponent: React.ComponentType<any>): string {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}

export const t: T = (message, data = {}) => format(gt.gettext(message), data);

export const tp: TP = (
  message, messagePlural, count, data = {},
) => format(gt.ngettext(message, messagePlural, count), {
  count,
  ...data,
});

export const tc: TC = (message, context, data = {}) => format(gt.pgettext(context, message), data);

export const tcp: TCP = (
  message, messagePlural, count, context, data = {},
) => format(gt.npgettext(context, message, messagePlural, count), {
  count,
  ...data,
});

export function withTranslation(WrappedComponent: React.ComponentType<any>) {
  class WithTranslation extends React.Component<{}> {
    render() {
      const translateFunctions = {
        t,
        tp,
        tc,
        tcp,
      };
      return <WrappedComponent {...translateFunctions} {...this.props} />;
    }
  }
  WithTranslation.displayName = `withTranslation(${getDisplayName(WrappedComponent)})`;

  return WithTranslation;
}
