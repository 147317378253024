import ReactDOM from 'react-dom';
import React from 'react';
import h from 'hyperscript';
import getTarget from 'javascripts/utils/get-target';
import Dialog from 'components/_particles/dialog/Dialog';

const mapSelect = ($mapSelect) => {
  $mapSelect.querySelector('svg').setAttribute('aria-hidden', true);
  $mapSelect.querySelectorAll('.map-select__link').forEach(($link) => {
    $link.setAttribute('tabindex', '-1');
  });
};

// Hide map from screenreaders
document.querySelectorAll('.js-map-select')
  .forEach($mapSelect => mapSelect($mapSelect));

// Redirect to select value
document.addEventListener('change', (event) => {
  const $target = getTarget(event.target, '.js-map-select__select .select');

  if ($target) {
    window.location.href = $target.value;
  }
});

const openMapDialog = (headline, mainTitle, mainLink, subLinks, $trigger = null) => {
  const $dialogParent = h('#map-select-dialog');
  const $mountPoint = h('#map-select-dialog-mountpoint');
  const $wrapper = h('div', $dialogParent, $mountPoint);
  document.body.appendChild($wrapper);

  ReactDOM.render(<Dialog closeButton={true} dialogParent={$dialogParent} className="map-select__dialog" onClose={() => {
    ReactDOM.unmountComponentAtNode($mountPoint);
    $wrapper.parentNode.removeChild($wrapper);

    if ($trigger) {
      $trigger.classList.remove('map-select__link--active');
    }
  }}>

    <div className="map-select__dialog-inner">
      {headline && <h1 className="headline headline--4 map-select__headline">{headline}</h1>}

      {(mainLink || subLinks) && <div className="text text--small map-select__text">
        <ul>
          {mainLink && <li><a className="link link--internal" href={mainLink}>{mainTitle}</a></li>}
          {subLinks && subLinks.map($item => <li key={$item.title}><a className="link link--internal" href={$item.link}>{$item.title}</a></li>)}
        </ul>
      </div>}
    </div>
  </Dialog>, $mountPoint);
};

document.addEventListener('click', (event) => {
  const $target = getTarget(event.target, '.map-select__link');

  if ($target) {
    event.preventDefault();

    $target.classList.add('map-select__link--active');

    const headline = $target.querySelector('.map-select__title').innerHTML;
    const mainTitle = $target.getAttribute('title');
    const mainLink = $target.getAttribute('xlink:href');
    const $subGroup = Array.prototype.slice.call($target.querySelectorAll('.map-select__content'));

    if ($subGroup) {
      const subLinks = $subGroup.map($item => ({
        title: $item.textContent,
        link: $item.getAttribute('data-link'),
      }));

      openMapDialog(headline, mainTitle, mainLink, subLinks, $target);
    } else {
      openMapDialog(headline, mainTitle, mainLink, $target);
    }
  }
});

export default openMapDialog;
