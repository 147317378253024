// @flow
import * as React from 'react';
import classNames from 'classnames';

type Props = {
  items: Array<string | React.Node>,
  className?: string,
};

const SimpleList = (props: Props) => {
  const classes = classNames(
    {
      'simple-list': true,
    },
    props.className,
  );

  return (
    <ul className={classes}>
      {props.items.map((item, index) => (
        <li className="simple-list__item" key={index}>
          {item}
        </li>
      ))}
    </ul>
  );
};

export default SimpleList;
