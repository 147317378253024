const unvisibleFocus = ($element, options = null) => {
  // Blur event
  const blurEvent = () => {
    $element.classList.remove('u-unvisible-focus');
    $element.removeEventListener('blur', blurEvent);
  };

  // Add blur event
  $element.addEventListener('blur', blurEvent);

  // Set class
  $element.classList.add('u-unvisible-focus');

  // Set focus
  return $element.focus(options);
};

export default unvisibleFocus;
