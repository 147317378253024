// @flow
import * as React from 'react';
import IconLink, { type Link } from '../../atoms/icon-link/IconLink';

type Props = {
  links: Array<Link>,
};

const renderLink = (link, index) => (
  <li key={index} className="icon-link-group__item">
    <IconLink {...link} />
  </li>
);

const IconLinkGroup = (props: Props) => (
  <div className="icon-link-group">
    <ul className="icon-link-group__list">{props.links.map(renderLink)}</ul>
  </div>
);

export default IconLinkGroup;
