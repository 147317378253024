import fitty from 'fitty';

document.querySelectorAll('.highlight-teaser__highlight').forEach(($highlight) => {
  // eslint-disable-next-line no-param-reassign
  $highlight.innerHTML = `<span>${$highlight.innerText}</span>`;
  const $wrap = $highlight.querySelector('span');

  fitty($wrap, {
    minSize: 40,
    maxSize: 120,
  });
});
