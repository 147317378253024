import { MEDIA_QUERIES } from 'javascripts/constants';
import Headroom from './headroom';

let componentStore = [];

function onMediaQueryChange(mq) {
  const isDesktop = mq.matches;

  // Disable all inited components
  componentStore.forEach(component => component.deconstructor && component.deconstructor());
  componentStore = [];

  // Init desktop
  if (isDesktop) {
    const $breadcrumb = document.querySelector('.breadcrumb');
    const $searchInput = document.querySelector('.simple-header .search-input, .header .search-input');
    const logo = $searchInput && $searchInput.closest('.header');

    if ($breadcrumb && $searchInput) {
      componentStore.push(new Headroom($breadcrumb, $searchInput, logo));
    }
  }
}

const mql = window.matchMedia(MEDIA_QUERIES.l);

mql.addListener(onMediaQueryChange);

onMediaQueryChange(mql);
