// @flow
import * as React from 'react';
import MediaQuery from 'react-responsive';
import classNames from 'classnames';
import Dialog from '../../../_particles/dialog/Dialog';
import Button from '../../../atoms/button/Button';
import { MEDIA_QUERIES } from '../../../../javascripts/constants';
import { t } from '../../../../javascripts/utils/withTranslation';

export type Item = {
  title: string,
  link: string,
  sectionName?: string,
  sectionSearch?: string,
  sectionButton?: string,
  items: Array<{
    title: string,
    link: string,
  }>,
}

type Props = {
  onClose: () => void,
  dialogParent: Element,
  items: Array<Item>,
};

type State = {
  currentOpenIndex: number,
};

export default class HeaderNavigationDialog extends React.Component<Props, State> {
  state = {
    currentOpenIndex: 0,
  }

  onMenuClick(index: number) {
    const { currentOpenIndex } = this.state;

    if (currentOpenIndex !== index) {
      // Set current open index
      this.setState({
        currentOpenIndex: index,
      });
    }

    // etracker
    // eslint-disable-next-line
    _etracker.sendEvent(new et_ClickEvent('Hauptmenü', this.props.items[index].title));
  }

  onClick(event: SyntheticEvent<HTMLAnchorElement>) {
    if (event.currentTarget.hash && this.isSamePage(event.currentTarget)) {
      this.onCompleteCloseClick();
    }
  }

  onCompleteCloseClick() {
    // Close dialog
    this.props.onClose();

    // Close hamburger menu
    const closeEvent: CustomEvent = new CustomEvent('overlayClose');
    document.dispatchEvent(closeEvent);
  }

  isSamePage($link: HTMLAnchorElement) {
    return (
      $link.protocol !== window.location.protocol
      || $link.host !== window.location.host
      || $link.pathname !== window.location.pathname
      || $link.search !== window.location.search
    ) === false;
  }

  customDialogHead() {
    const { onClose } = this.props;

    return <MediaQuery query={MEDIA_QUERIES.l}>
      {matches => (matches ? (
        <Button
          onClick={onClose}
          icon="close"
          outline={true}
          small={true}
          className="header__dialog-close"
        >
          {t('Schließen')}
        </Button>
      ) : (
        <div className="header__dialog-close-bar">
          <Button
            onClick={onClose}
            icon="left"
            outline={true}
            small={true}
          >
            {t('Zurück')}
          </Button>

          <button
            onClick={this.onCompleteCloseClick.bind(this)}
            className="header__toggle header__toggle--open"
            type="button"
          >
            <span className="header__toggle-icon" />
            <span className="u-hidden-visually">{t('Menü schließen')}</span>
          </button>
        </div>
      ))}
    </MediaQuery>;
  }

  render() {
    const { onClose, items, dialogParent } = this.props;
    const { currentOpenIndex } = this.state;

    return (
      <Dialog
        onClose={onClose}
        className="header__dialog"
        dialogParent={dialogParent}
        customHead={this.customDialogHead.bind(this)}
        closeButton={false}
      >
        <div className="header__dialog-inner">
          <div className="header__dialog-menu-content">
            <ul className="header__dialog-menu">
              {items.map((item, index) => (
                <li key={index} id={`navigation-item-${index}`} className="header__dialog-menu-item-wrap">
                  {item.items.length > 0 ? (
                    <button
                      type="button"
                      className={classNames(
                        'header__dialog-menu-item',
                        item.items.length > 0 && 'header__dialog-menu-item--with-children',
                        currentOpenIndex === index && 'header__dialog-menu-item--active',
                      )}
                      aria-controls={item.items.length > 0 && `header-dialog-${index}-submenu`}
                      onClick={() => this.onMenuClick(index)}
                    >
                      <span className="header__dialog-menu-item-inner">
                        {item.title}
                      </span>
                    </button>
                  ) : (
                    <a href={item.link} className="header__dialog-menu-item" onClick={this.onClick.bind(this)}>
                      <span className="header__dialog-menu-item-inner">
                        {item.title}
                      </span>
                    </a>
                  )}

                  <div
                    className="header__dialog-content"
                    hidden={currentOpenIndex !== index}
                    id={`header-dialog-${index}-submenu`}
                  >
                    {item.items.length > 0 && (
                      <ul className={classNames({
                        'header__dialog-submenu': true,
                        'header__dialog-submenu--visible': currentOpenIndex === index,
                      })}>
                        {item.items.map((subItem, subIndex) => (
                          <li key={subIndex} className="header__dialog-submenu-item-wrap">
                            <a href={subItem.link} className="header__dialog-submenu-item" onClick={this.onClick.bind(this)}>
                              <span className="header__dialog-submenu-item-inner">{subItem.title}</span>
                            </a>
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </Dialog>
    );
  }
}
