import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

export default class HeaderToggleButton {
  constructor($toggleButton) {
    this.$toggleButton = $toggleButton;
    this.$menu = document.getElementById(this.$toggleButton.getAttribute('aria-controls'));

    // Binded events
    this.bindedOnOverlayClose = this.onOverlayClose.bind(this);
    this.bindedOnClick = this.onClick.bind(this);

    // Add events
    document.addEventListener('overlayClose', this.bindedOnOverlayClose);
    this.$toggleButton.addEventListener('click', this.bindedOnClick);
  }

  deconstructor() {
    // Remove events
    document.removeEventListener('overlayClose', this.bindedOnOverlayClose);
    this.$toggleButton.removeEventListener('click', this.bindedOnClick);

    // Reset panel
    this.closeMenu();
  }

  closeMenu() {
    // Change toggle button
    this.$toggleButton.setAttribute('aria-expanded', 'false');

    // Close menu
    this.$menu.classList.remove('header__menu--open');

    // Re-enable scrolling
    enableBodyScroll(this.$menu);

    // Add focus back on toggle button
    this.$toggleButton.focus();
  }

  openMenu() {
    // Change toggle button
    this.$toggleButton.setAttribute('aria-expanded', 'true');

    // Disable scrolling
    disableBodyScroll(this.$menu);

    // Show panel
    this.$menu.classList.add('header__menu--open');
  }

  onOverlayClose() {
    // Close menu if event to close all overlays is fired
    window.requestAnimationFrame(() => this.closeMenu());
  }

  onClick() {
    // Get current state of panel
    const isClosed = this.$toggleButton.getAttribute('aria-expanded') !== 'true';

    // Switch state
    window.requestAnimationFrame(() => {
      if (isClosed) {
        this.openMenu();
      } else {
        this.closeMenu();
      }
    });
  }
}
