// @flow
import React from 'react';
import classNames from 'classnames';
import Icon from '../../_particles/icon/Icon';

export type Props = {
  label?: string,
  tagName?: string | 'div',
  className?: string,
  icon?: string,
};

const Spinner = (props: Props) => {
  const {
    label,
    className,
    tagName,
    icon,
  } = props;

  const TagName = tagName || 'div';

  return (
    <TagName
      className={classNames(
        'spinner',
        className,
      )}
    >
      {icon ? (
        <span className="spinner__icon">
          <Icon icon={icon} />
        </span>
      ) : (
        <span className="spinner__indicator" />
      )}

      {label && (
        <span className="spinner__label">
          {label}
        </span>
      )}
    </TagName>
  );
};

export default Spinner;
