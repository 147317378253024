import Scrollable from 'javascripts/utils/scrollable';
import getTarget from 'javascripts/utils/get-target';
import getIndexForElement from 'javascripts/utils/get-index-for-element';
import scroll from 'scroll';

function open($person) {
  if ($person.classList.contains('person-slider__person--open')) {
    return;
  }

  const $container = $person.closest('.person-slider__people');
  $container.querySelectorAll('.person-slider__person--open').forEach(($el) => {
    $el.classList.remove('person-slider__person--open');
  });

  const index = getIndexForElement($person);
  $person.classList.add('person-slider__person--open');

  let imageWidth = 80;
  if (window.innerHeight >= 830) {
    imageWidth = 140;
  } else if (window.innerHeight >= 580) {
    imageWidth = 120;
  } else if (window.innerHeight >= 375) {
    imageWidth = 100;
  }

  scroll.left($container, (index - 1) * imageWidth);
}

// Open on click
document.addEventListener('click', (event) => {
  const $person = getTarget(event.target, '.person-slider__person');

  if ($person) {
    open($person);
  }
});

// Open on focus
document.addEventListener('focusin', (event) => {
  const $person = getTarget(event.target, '.person-slider__person');

  if ($person) {
    open($person);
  }
});

// Init scrollable
document.querySelectorAll('.person-slider__people-wrap').forEach($personSlider => (
  new Scrollable(
    $personSlider,
    $personSlider.querySelector('.person-slider__people'),
    'person-slider__button',
  )
));
