import { render } from 'react-dom';
import React from 'react';
import Gallery from './Gallery';

document.querySelectorAll('.gallery').forEach(($gallery) => {
  const images = [];

  $gallery.querySelectorAll('a.gallery__panel').forEach(($link, index) => {
    const $imageWrapper = $link.querySelector('.gallery__image');
    const $image = $imageWrapper.querySelector('.image__img');

    if ($image) {
      images.push({
        index,
        link: $link.href,
        src: $image.getAttribute('data-src'),
        width: parseInt($image.getAttribute('width'), 10),
        height: parseInt($image.getAttribute('height'), 10),
        alt: $image.getAttribute('alt') || null,
        caption: $imageWrapper.getAttribute('data-caption') || null,
        source: $imageWrapper.getAttribute('data-source') || null,
      });
    }
  });

  render(<Gallery images={images} />, $gallery);
});
