import { lory } from '@rsm/allfarblori';
import h from 'hyperscript';
import { generateIcon as icon } from 'components/_particles/icon/Icon';
import { t } from '../../../javascripts/utils/withTranslation';

export default class Slider {
  constructor($el) {
    this.$el = $el;
    this.$slides = this.$el.querySelectorAll('.slider__slide');
    this.$inner = this.$el.querySelector('.slider__inner');
    this.$slidesContainer = this.$el.querySelector('.slider__slides-wrap');

    // Don't init slider, if only one slide exists
    if (this.$slides.length === 1) {
      return;
    }

    this.$el.addEventListener('before.lory.init', () => {
      this.$slidesContainer.scrollLeft = 0;
      this.initControls();
    });

    this.$el.addEventListener('after.lory.init', () => {
      this.$el.classList.add('slider--initialized');
      this.initAutoplay();
    });

    this.$el.addEventListener('after.lory.slide', () => {
      this.updateDots();
    });

    this.lory = lory(this.$el, {
      rewind: true,
      rewindPrev: true,
      rewindSpeed: 200,
      slideSpeed: 350,
      ease: 'cubic-bezier(0.455, 0.03, 0.515, 0.955)',
      classNameFrame: 'slider__slides-wrap',
      classNameSlideContainer: 'slider__slides',
      classNamePrevCtrl: 'slider__arrow--prev',
      classNameNextCtrl: 'slider__arrow--next',
      classNameDisabledPrevCtrl: 'slider__arrow--disabled',
      classNameDisabledNextCtrl: 'slider__arrow--disabled',
    });

    this.$slidesContainer.addEventListener('focusin', (event) => {
      this.$slidesContainer.scrollLeft = 0;
      const $slide = event.target.closest('.slider__slide');
      const index = Array.prototype.indexOf.call(this.$slides, $slide);
      this.lory.slideTo(index);
    });
  }

  initControls() {
    this.$controlPrev = h(
      'button.slider__arrow.slider__arrow--prev',
      {
        type: 'button',
        tabIndex: '-1',
        title: t('Vorheriger Slide'),
      },
      icon({
        icon: 'slim-arrow',
        viewBox: '0 0 70 200',
        className: 'slider__arrow-icon',
      }),
      h('span.u-hidden-visually', t('Vorheriger Slide')),
    );

    this.$controlNext = h(
      'button.slider__arrow.slider__arrow--next',
      {
        type: 'button',
        tabIndex: '-1',
        title: t('Nächster Slide'),
      },
      icon({
        icon: 'slim-arrow',
        viewBox: '0 0 70 200',
        className: 'slider__arrow-icon',
      }),
      h('span.u-hidden-visually', t('Nächster Slide')),
    );

    this.$dots = h('.dots', [...this.$slides].map((_, index) => h(
      `button.dots__dot${index === 0 ? '.dots__dot--active' : ''}`,
      {
        type: 'button',
        tabIndex: '-1',
        title: t('Zum Slide {index}', { index: index + 1 }),
        onclick: () => this.lory.slideTo(index),
      },
      h('span.u-hidden-visually', t('Zum Slide {index}', { index: index + 1 })),
    )));

    this.$controlPlay = h('button.slider__play-control', {
      type: 'button',
      title: t('Slides automatisch abspielen'),
      attrs: {
        'aria-pressed': this.isAutoplay() ? 'true' : 'false',
      },
    }, h('span.u-hidden-visually', t('Slides automatisch abspielen')));

    this.$dotsWrapper = h('.slider__dots', this.$dots, this.$controlPlay);

    this.$inner.appendChild(this.$controlPrev);
    this.$inner.appendChild(this.$controlNext);
    this.$inner.appendChild(this.$dotsWrapper);
  }

  isPlaying() {
    return (this.$el.getAttribute('data-playing') === 'true');
  }

  isAutoplay() {
    return (this.$el.getAttribute('data-autoplay') === 'true');
  }

  initAutoplay() {
    // Touch kill
    let hasTouched = false;

    // Init timer
    const timerInit = () => setInterval(() => this.lory.next(), 5000);
    let timer = this.isPlaying() ? timerInit() : false;

    // Kill timer if a mouse enters the slider
    this.$el.addEventListener('mouseover', () => {
      // this.$el.setAttribute('data-playing', 'false');
      clearInterval(timer);
    });

    // Restart timer if a mouse leaves the slider
    this.$el.addEventListener('mouseleave', () => {
      if (!hasTouched && this.isPlaying()) timer = timerInit();
    });

    // Kill the timer if at least on touch interaction happend
    this.$el.addEventListener('touchstart', function killOnTouchStart() {
      clearInterval(timer);
      this.$el.revomeEventListener('touchstart', killOnTouchStart);
      hasTouched = true;
    }, { passive: true });

    // Autoplay toggle button (play/pause)
    this.$controlPlay.addEventListener('click', () => {
      if (this.isPlaying()) {
        this.$controlPlay.setAttribute('aria-pressed', 'false');
        this.$el.setAttribute('data-playing', 'false');
        clearInterval(timer);
      } else {
        this.$controlPlay.setAttribute('aria-pressed', 'true');
        this.$el.setAttribute('data-playing', 'true');
        if (!hasTouched) timer = timerInit();
        setTimeout(() => this.lory.next(), 500);
      }
    });
  }

  updateDots() {
    this.$el.querySelector('.dots__dot--active')
      .classList.remove('dots__dot--active');

    this.$el.querySelectorAll('.dots__dot')[this.lory.returnIndex()]
      .classList.add('dots__dot--active');
  }
}

document.querySelectorAll('.slider').forEach($slider => new Slider($slider));
