import ReactDOM from 'react-dom';
import React from 'react';
import h from 'hyperscript';
import getTarget from 'javascripts/utils/get-target';
import Dialog from 'components/_particles/dialog/Dialog';

const openMatrixDialog = ($content, $trigger = null) => {
  // Prepare mount point
  const $dialogParent = h('#matrix-dialog');
  const $mountPoint = h('#matrix-mountpoint');
  const $wrapper = h('div', $dialogParent, $mountPoint);
  document.body.appendChild($wrapper);

  // Render dialog
  ReactDOM.render(<Dialog closeButton={true} dialogParent={$dialogParent} className={`matrix__dialog ${$trigger.dataset.narrow && 'matrix__dialog--narrow'}`} onClose={() => {
    ReactDOM.unmountComponentAtNode($mountPoint);
    $wrapper.parentNode.removeChild($wrapper);

    if ($trigger) {
      $trigger.focus();
    }
  }}>
    <div className="matrix__dialog-inner" dangerouslySetInnerHTML={{ __html: $content.innerHTML }} />
  </Dialog>, $mountPoint);
};

// Open by click
document.addEventListener('click', (event) => {
  const $target = getTarget(event.target, '.matrix__button');

  if ($target && $target.getAttribute('aria-controls')) {
    const contentId = $target.getAttribute('aria-controls');

    if (document.getElementById(contentId)) {
      // Prevent navigation
      event.preventDefault();

      // Get content
      const $content = document.getElementById(contentId);

      // Open dialog
      openMatrixDialog($content, $target);
    }
  }
});

// Open by class on load
const $firstMatchOnLoad = document.querySelector('.matrix__button--open-on-load');
if ($firstMatchOnLoad) {
  const contentId = $firstMatchOnLoad.getAttribute('aria-controls');

  if (document.getElementById(contentId)) {
    // Get content
    const $content = document.getElementById(contentId);

    // Open dialog
    openMatrixDialog($content, document.body);
  }
}

export default openMatrixDialog;
