// @flow
import h from 'hyperscript';
import { lory } from '@rsm/allfarblori';
import { MEDIA_QUERIES } from '../../../javascripts/constants';
import mediaQueryWatcher from '../../../javascripts/utils/media-query-watcher';
import type { CallbackValue as MediaQueryWatcher } from '../../../javascripts/utils/media-query-watcher';
import { t } from '../../../javascripts/utils/withTranslation';

function querySelector($el: HTMLElement, selector: string): HTMLElement {
  const $result = $el.querySelector(selector);
  if (!$result) throw new Error(`Failed to match: ${selector}`);
  return $result;
}

export type Multiplicators = {[string]: number};

export default class TeaserSlider {
  // DOM structure
  $teaserSlider: HTMLElement;

  teasers: NodeList<HTMLElement>;

  $inner: HTMLElement;

  $teasers: HTMLElement;

  $dots: HTMLElement;

  // Lory and media queries
  lory: Object;

  mediaQueries: MediaQueryWatcher;

  multiplicators: Multiplicators;

  // Events
  onBeforeInitBinded: EventListener;

  onAfterInitBinded: EventListener;

  onAfterSlideBinded: EventListener;

  constructor($teaserSlider: HTMLElement, multiplicators: Multiplicators = {
    s: 1, m: 2, l: 3, xl: 4,
  }) {
    // Multiplicators
    this.multiplicators = multiplicators;

    // Get DOM elements
    this.$teaserSlider = $teaserSlider;
    this.teasers = this.$teaserSlider.querySelectorAll('.teaser-slider__teaser');
    this.$inner = querySelector(this.$teaserSlider, '.teaser-slider__inner');
    this.$teasers = querySelector(this.$teaserSlider, '.teaser-slider__teasers');

    // Create media query watcher
    this.mediaQueries = mediaQueryWatcher(
      Object.entries(MEDIA_QUERIES).reverse().map(([name, query]) => ({ name, query })),
      (mediaQueries) => {
        this.mediaQueries = mediaQueries;

        // Deinit on all viewports
        this.deinit();

        // Reinit lory
        this.init();
      },
    );

    // Init lory
    this.init();
  }

  deinit() {
    // Check if already inited
    if (!this.lory) {
      return;
    }

    // Remove events
    this.$teaserSlider.removeEventListener('before.lory.init', this.onBeforeInitBinded);
    this.$teaserSlider.removeEventListener('after.lory.init', this.onAfterInitBinded);
    this.$teaserSlider.removeEventListener('after.lory.slide', this.onAfterSlideBinded);

    // Remove dots
    if (this.$dots.parentNode) {
      this.$dots.parentNode.removeChild(this.$dots);
    }
    delete this.$dots;

    // Deinit
    this.lory.destroy();
    delete this.lory;

    // Set scrollLeft
    this.$inner.scrollLeft = 0;

    // Remove all styles
    this.$teaserSlider.classList.remove('teaser-slider--initialized');
    this.$teasers.removeAttribute('style');
  }

  init() {
    // Check if already inited
    if (this.lory) {
      return;
    }

    // Register events
    this.$teaserSlider.addEventListener('before.lory.init', this.onBeforeInitBinded = this.onBeforeInit.bind(this));
    this.$teaserSlider.addEventListener('after.lory.init', this.onAfterInitBinded = this.onAfterInit.bind(this));
    // $FlowFixMe https://github.com/facebook/flow/issues/3472
    this.$teaserSlider.addEventListener('after.lory.slide', this.onAfterSlideBinded = this.onAfterSlide.bind(this));

    // Init lory
    this.lory = lory(this.$teaserSlider, {
      slideSpeed: 350,
      ease: 'cubic-bezier(0.455, 0.03, 0.515, 0.955)',
      classNameFrame: 'teaser-slider__inner',
      classNameItem: 'teaser-slider__teaser',
      classNameSlideContainer: 'teaser-slider__teasers',
    });
  }

  onBeforeInit() {
    this.$inner.scrollLeft = 0;
  }

  onAfterInit() {
    // Add class
    this.$teaserSlider.classList.add('teaser-slider--initialized');

    // Add dots navigation
    this.generateDotNavigation();
  }

  onAfterSlide(event: CustomEvent) {
    const currentBreakpoint = this.getCurrentBreakpoint();

    // Update dots
    this.$teaserSlider.querySelectorAll('.dots__dot').forEach(($dot, index) => {
      $dot.classList.remove('dots__dot--active');

      const { currentSlide } = event.detail;
      const currentPage = currentSlide > 0
        ? Math.round(currentSlide / this.multiplicators[currentBreakpoint]) : 0;

      if (currentPage === index) {
        $dot.classList.add('dots__dot--active');
      }
    });
  }

  getCurrentBreakpoint(): string {
    return (this.mediaQueries.currentMediaQuery && this.mediaQueries.currentMediaQuery.name) || 's';
  }

  generateDotNavigation() {
    const currentBreakpoint = this.getCurrentBreakpoint();

    // Delete old dot navigation
    if (this.$dots && this.$dots.parentNode) {
      this.$dots.parentNode.removeChild(this.$dots);
    }

    // How many teasers?
    let teasers = this.teasers.length;
    let firstFullWidth = false;

    // 50-25-25
    if (currentBreakpoint !== 's' && this.$teaserSlider.classList.contains('teaser-slider--50-25-25')) {
      teasers += 1;
      firstFullWidth = true;
    }

    // 50-50
    if (currentBreakpoint === 'l' && this.$teaserSlider.classList.contains('teaser-slider--50-50')) {
      teasers *= 1.5;
    }

    if (currentBreakpoint === 'xl' && this.$teaserSlider.classList.contains('teaser-slider--50-50')) {
      teasers *= 2;
    }

    // How many dots?
    const dots = Math.ceil(teasers / this.multiplicators[currentBreakpoint]);

    // Add dots navigation
    this.$dots = h('.teaser-slider__dots', h('.dots', Array(dots).fill().map((a, index) => h(
      `button.dots__dot${index === 0 ? '.dots__dot--active' : ''}`,
      {
        type: 'button',
        tabIndex: '-1',
        title: t('Zur Seite {index}', { index: index + 1 }),
        onclick: () => {
          this.lory.slideTo((
            index * this.multiplicators[currentBreakpoint]
          ) - (
            firstFullWidth ? 1 : 0
          ));
        },
      },
      h('span.u-hidden-visually', t('Zur Seite {index}', { index: index + 1 })),
    ))));

    // Add dots navigation
    if (dots > 1) {
      this.$teaserSlider.appendChild(this.$dots);
    }
  }
}

document.querySelectorAll('.teaser-slider').forEach(($el) => {
  let multiplicators = {
    s: 1, m: 2, l: 3, xl: 4,
  };

  try {
    if ($el.hasAttribute('data-multiplicators')) {
      multiplicators = JSON.parse($el.getAttribute('data-multiplicators') || '');
    }
  } catch {
    // Nothing
  }

  // $FlowFixMe
  $el.teaserSlider = new TeaserSlider($el, multiplicators); // eslint-disable-line no-param-reassign
});
