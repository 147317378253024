import getTarget from 'javascripts/utils/get-target';
import detectPrefixes from 'javascripts/utils/detect-prefixes';
import unvisibleFocus from 'javascripts/utils/unvisible-focus';

const prefixes = detectPrefixes();

export default class NavigationMobile {
  dontFocusBackToMoveLink = false;

  constructor($navigation) {
    this.$navigation = $navigation;

    // Events
    this.onMoveLinkClickBinded = this.onMoveLinkClick.bind(this);
    this.onBackLinkClickBinded = this.onBackLinkClick.bind(this);
    this.onFocusinBinded = this.onFocusin.bind(this);
  }

  init() {
    this.$navigation.addEventListener('click', this.onMoveLinkClickBinded);
    this.$navigation.addEventListener('click', this.onBackLinkClickBinded);
    this.$navigation.addEventListener('focusin', this.onFocusinBinded);

    // Add aria attributes
    this.$navigation
      .querySelectorAll('.simple-header__navigation-item-link.simple-header__navigation-move, .simple-header__navigation-submenu-item-link.simple-header__navigation-move')
      .forEach($el => $el.setAttribute('aria-expanded', 'false'));
  }

  deinit() {
    this.$navigation.removeEventListener('click', this.onMoveLinkClickBinded);
    this.$navigation.removeEventListener('click', this.onBackLinkClickBinded);
    this.$navigation.removeEventListener('focusin', this.onFocusinBinded);

    // Reset navigation
    this.$navigation.style.height = '';
    this.$navigation.classList.remove('simple-header__navigation--2nd-level', 'simple-header__navigation--3rd-level');

    // Find open submenus
    this.$navigation.querySelectorAll('.simple-header__navigation-submenu--current').forEach(($submenu) => {
      $submenu.classList.remove('simple-header__navigation-submenu--current');
    });

    // Remove aria attributes
    this.$navigation
      .querySelectorAll('.simple-header__navigation-item-link.simple-header__navigation-move, .simple-header__navigation-submenu-item-link.simple-header__navigation-move')
      .forEach($el => $el.removeAttribute('aria-expanded'));
  }

  adjustNavigationHeight() {
    let $reference = this.$navigation.querySelector('.simple-header__navigation-inner');

    if (this.$navigation.classList.contains('simple-header__navigation--3rd-level')) {
      $reference = this.$navigation.querySelector('.simple-header__navigation-submenu--current .simple-header__navigation-submenu--current');
    } else if (this.$navigation.classList.contains('simple-header__navigation--2nd-level')) {
      $reference = this.$navigation.querySelector('.simple-header__navigation-submenu--current');
    }

    const dimensions = $reference.getBoundingClientRect();
    this.$navigation.style.height = `${dimensions.height}px`;
    this.$navigation.scrollTop = 0;
  }

  onMoveLinkClick(event) {
    const $moveLink = getTarget(event.target, '.simple-header__navigation-move');

    if ($moveLink) {
      event.preventDefault();

      const $submenu = $moveLink.parentNode.querySelector('.simple-header__navigation-submenu');

      // Already active?
      if ($submenu.classList.contains('simple-header__navigation-submenu--current')) {
        return;
      }

      // Make submenu visible
      $submenu.classList.add('simple-header__navigation-submenu--current');

      // Adjust height
      this.adjustNavigationHeight();

      // Move navigation to the next level
      if (!this.$navigation.classList.contains('simple-header__navigation--2nd-level')) {
        this.$navigation.classList.add('simple-header__navigation--2nd-level');
      } else {
        this.$navigation.classList.add('simple-header__navigation--3rd-level');
      }

      // Set aria attribute
      $moveLink.setAttribute('aria-expanded', 'true');

      // Set focus to the first element
      const setFocus = () => {
        const $firstElement = $submenu.querySelector('.simple-header__navigation-submenu-item:not(.simple-header__navigation-submenu-item--back)');
        const $link = $firstElement.querySelector('.simple-header__navigation-submenu-item-link');
        unvisibleFocus($link);
        this.$navigation.removeEventListener(prefixes.transitionEnd, setFocus);
      };
      this.$navigation.addEventListener(prefixes.transitionEnd, setFocus);

      // Adjust height
      this.adjustNavigationHeight();
    }
  }

  onBackLinkClick(event) {
    const $backLink = getTarget(event.target, '.simple-header__navigation-back');

    if ($backLink) {
      event.preventDefault();

      // Get elements
      const $submenu = $backLink.closest('.simple-header__navigation-submenu');
      const $item = $submenu.closest('.simple-header__navigation-submenu-item, .simple-header__navigation-item');

      // Move navigation to the upper level
      if (this.$navigation.classList.contains('simple-header__navigation--3rd-level')) {
        this.$navigation.classList.remove('simple-header__navigation--3rd-level');
      } else {
        this.$navigation.classList.remove('simple-header__navigation--2nd-level');
      }

      // Hide submenu
      setTimeout(() => {
        $submenu.classList.remove('simple-header__navigation-submenu--current');
      }, 500);

      // Remove aria attribute
      const $moveLink = $item.querySelector('.simple-header__navigation-move');
      $moveLink.setAttribute('aria-expanded', 'false');

      // Set focus to move link back
      if (!this.dontFocusBackToMoveLink) {
        unvisibleFocus($moveLink);
      }

      // Adjust height
      this.adjustNavigationHeight();

      // Reset
      this.dontFocusBackToMoveLink = false;
    }
  }

  onFocusin(event) {
    const $1stLevel = getTarget(event.target, '.simple-header__navigation-item-link');
    const $2ndLevel = getTarget(event.target, '.simple-header__navigation-submenu--2nd-level > .simple-header__navigation-submenu-item > .simple-header__navigation-submenu-item-link');
    const $winner = $1stLevel || $2ndLevel || false;

    if ($winner) {
      if (
        ($1stLevel && this.$navigation.classList.contains('simple-header__navigation--2nd-level'))
        || ($2ndLevel && this.$navigation.classList.contains('simple-header__navigation--3rd-level'))
      ) {
        // Find open submenu
        const allOpenSubmenu = Array.from(this.$navigation.querySelectorAll('.simple-header__navigation-submenu--current'));

        // Don't focus back to the back link
        this.dontFocusBackToMoveLink = true;

        // Close all
        if ($1stLevel) {
          allOpenSubmenu.reverse().forEach(($submenu) => {
            const $backLink = $submenu.querySelector('.simple-header__navigation-back');
            $backLink.click();
          });

        // Close last one
        } else {
          const $submenu = allOpenSubmenu[allOpenSubmenu.length - 1];
          const $backLink = $submenu.querySelector('.simple-header__navigation-back');
          $backLink.click();
        }

        // Focus back
        unvisibleFocus($winner);
      }
    }
  }
}
