/**
 * Detecting prefixes for saving time and bytes
 */
export default function detectPrefixes() {
  let transform = 'transform';
  let transition = 'transition';
  let transitionEnd = 'transitionend';
  let order = 'order';
  let hasTranslate3d;

  const detection = () => {
    const el = document.createElement('_');
    const { style } = el;

    if (style.webkitTransition === '') {
      transitionEnd = 'webkitTransitionEnd';
      transition = 'webkitTransition';
    }

    if (style.webkitTransform === '') {
      transform = 'webkitTransform';
    }

    if (style.msTransform === '') {
      transform = 'msTransform';
    }

    if (style['-ms-flex-order'] === '') {
      order = '-ms-flex-order';
    }

    document.body.insertBefore(el, null);
    style[transform] = 'translate3d(0, 0, 0)';
    hasTranslate3d = !!global.getComputedStyle(el).getPropertyValue(transform);
    document.body.removeChild(el);
  };

  // Run detection
  detection();

  return {
    transform,
    transition,
    transitionEnd,
    order,
    hasTranslate3d,
  };
}
