import Gettext from 'node-gettext';
import { LANG } from '../constants';

const gt = new Gettext({
  debug: false,
});

// Register translations
if (window.translations) {
  Object.keys(window.translations).forEach(lang => gt.addTranslations(lang, 'messages', window.translations[lang]));
}

// Set current locale, if not German
if (LANG !== 'de') {
  gt.setLocale(LANG);
}

export default gt;
