import h from 'hyperscript';
import Headroom from 'headroom.js';
import Detabinator from 'javascripts/utils/detabinator';
import { TITLE } from 'javascripts/constants';

export default class HeadroomClass {
  constructor($breadcrumb, $searchInput, logo = true) {
    this.$breadcrumb = $breadcrumb.cloneNode(true);
    this.$searchInput = $searchInput.cloneNode(true);
    this.$logo = this.createLogoElement();

    this.$headroom = h(
      '.headroom',
      logo && h('.headroom__logo', this.$logo),
      h('.headroom__breadcrumb', this.$breadcrumb),
      h('.headroom__search-input', this.$searchInput),
    );

    this.$headroom.setAttribute('aria-hidden', 'true');
    this.$headroom.detabinator = new Detabinator(this.$headroom, true);
    document.body.appendChild(this.$headroom);

    const headroom = new Headroom(this.$headroom, {
      offset: 300,
      tolerance: {
        up: 0,
        down: 10,
      },
    });

    headroom.init();
  }

  deconstructor() {
    document.body.removeChild(this.$headroom);
  }

  createLogoElement() {
    const xmlns = 'http://www.w3.org/2000/svg';

    const $logo = document.createElement('a');
    $logo.setAttribute('class', 'logo logo--small');
    $logo.setAttribute('href', '/');

    const $svg = document.createElementNS(xmlns, 'svg');
    $svg.setAttributeNS(null, 'viewBox', '0 0 230 230');
    $svg.setAttributeNS(null, 'role', 'img');
    $svg.setAttributeNS(null, 'class', 'logo__image');
    $svg.setAttributeNS(null, 'aria-labelledby', 'headroom-logo-title');
    $logo.appendChild($svg);

    const $title = document.createElementNS(xmlns, 'title');
    $title.setAttributeNS(null, 'id', 'headroom-logo-title');
    $title.appendChild(document.createTextNode(TITLE));
    $svg.appendChild($title);

    const $use = document.createElementNS(xmlns, 'use');
    $use.setAttributeNS(
      'http://www.w3.org/1999/xlink',
      'xlink:href',
      '#icon-logo-small',
    );
    $svg.appendChild($use);

    return $logo;
  }
}
