document.querySelectorAll('.faq-teasers').forEach(($faqTeasers) => {
  $faqTeasers.addEventListener('selectTab', (event) => {
    // Get current panel
    const $currentPanel = $faqTeasers.querySelector('.faq-teasers__section:not([hidden])');

    // Hide current panel
    $currentPanel.removeAttribute('tabindex');
    $currentPanel.setAttribute('hidden', true);

    // Find new panel
    const $headline = $faqTeasers.querySelector(event.target.getAttribute('href'));
    const $panel = $headline.closest('.faq-teasers__section');

    // Make panel visible
    $panel.removeAttribute('hidden');

    // Refresh lory
    const $teaserSlider = $panel.querySelector('.teaser-slider');
    if ($teaserSlider.teaserSlider && $teaserSlider.teaserSlider.lory) {
      $teaserSlider.teaserSlider.lory.reset();
    }

    // Focus
    const { repeated = false } = event.detail;
    if (!repeated) $headline.focus();
  });

  // Resend events
  const $tabBar = $faqTeasers.querySelector('.tab-bar');
  $tabBar.dispatchEvent(new CustomEvent('resendEvents', {
    bubbles: true,
  }));
});
