// @flow
import * as React from 'react';

type State = {
  loading: boolean,
  data: ?{ [string]: any },
  error: boolean,
  pastDelay: boolean,
};

type Props = {
  apiUrl: string,
  delay?: number,
  children: State => React.Node,
};

export default class Fetch extends React.Component<Props, State> {
  delay: ?TimeoutID;

  state = {
    loading: true,
    data: null,
    error: false,
    pastDelay: true,
  };

  constructor(props: Props) {
    super(props);

    if (props.delay) {
      this.state.pastDelay = false;
    }
  }

  async componentDidMount() {
    const { apiUrl, delay } = this.props;

    if (delay) {
      this.delay = setTimeout(() => {
        this.setState({ pastDelay: true });
      }, delay);
    }

    try {
      const response = await fetch(apiUrl);
      const data = await response.json();

      this.setState({
        loading: false,
        data,
        error: false,
      });
    } catch (error) {
      this.setState({
        loading: false,
        data: null,
        error: true,
      });
    }
  }

  componentWillUnmount() {
    if (this.delay) {
      clearTimeout(this.delay);
    }
  }

  render() {
    return this.props.children(this.state);
  }
}
