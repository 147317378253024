// @flow
import * as React from 'react';
import type { Item } from './HeaderNavigationDialog';

const SectionList = ({ item }: { item: Item }) => (
  item.items.length > 0 && <ul className="header__dialog-list">
    {item.items.map(($item, index) => (
      <li key={index} className="header__dialog-list-item">
        <a href={$item.link} className="header__dialog-link">
          <span className="header__dialog-link-inner">
            {$item.title}
          </span>
        </a>
      </li>
    ))}
  </ul>
);

const Section = ({ item }: { item: Item }) => (
  <div className="header__dialog-fadein">
    <SectionList item={item} />
  </div>
);

export default Section;
