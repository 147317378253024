document.querySelectorAll('.button-overlay').forEach((buttonOverlay) => {
  const actualButtonOverlay = buttonOverlay;
  actualButtonOverlay.onclick = function ButtonOnClick(e) {
    let overlay = '';
    let content = '';
    e.preventDefault();
    if (document.querySelectorAll('.button_overlay__overlay').length === 0) {
      document.body.insertAdjacentHTML('beforeend', '<div class="button-overlay__overlay"><div class="button-overlay__overlay__window"><div class="button-overlay__overlay__close"><a class="icon-link icon-link--small" href="#"><span class="icon-link__button"><svg class="icon icon--close icon-link__icon" viewBox="0 0 200 200" role="presentation"><use xlink:href="#icon-close"></use></svg></span><span class="icon-link__text">Schließen</span></a></div><div class="button-overlay__overlay__content"</div></div>');
      document.querySelectorAll('.button-overlay__overlay__close')[0].onclick = function CloseOnClick(event) {
        event.preventDefault();
        document.querySelectorAll('.button-overlay__overlay')[0].classList.toggle('active');
      };
    }
    overlay = document.querySelector('.button-overlay__overlay');
    content = actualButtonOverlay.querySelectorAll('.button-overlay__overlaycontent')[0].cloneNode(true);
    overlay.querySelectorAll('.button-overlay__overlay__content')[0].innerHTML = '';
    overlay.querySelectorAll('.button-overlay__overlay__content')[0].appendChild(content);
    overlay.classList.toggle('active');
    document.querySelectorAll('.button-overlay__overlay__content')[0].scrollTop = 0;
  };
});
