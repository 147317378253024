import h from 'hyperscript';
import { t } from 'javascripts/utils/withTranslation';
import getTarget from 'javascripts/utils/get-target';
import { autoLink } from 'twitter-text';

async function loadTweets() {
  const tweetsContent = document.querySelectorAll('.js-tweets');

  tweetsContent.forEach(async ($content) => {
    const response = await fetch($content.getAttribute('data-api'));
    const tweets = await response.json();
    const $list = h('ul.social-media-teaser__list');

    // Render tweets
    tweets.forEach((tweet) => {
      const text = autoLink(tweet.text, {
        hashtagClass: 'link',
        cashtagClass: 'link',
        listClass: 'link',
        usernameClass: 'link',
        urlClass: 'link',
        urlEntities: tweet.entities.urls,
      });

      $list.appendChild(h(
        'li.social-media-teaser__list-item',
        h('span.social-media-teaser__twitter-date', tweet.created_at),
        h('p.social-media-teaser__text', document.createRange().createContextualFragment(text)),
      ));
    });

    $content.appendChild($list);
  });
}

function switchFacebookTeasers() {
  const facebookTeaserLinks = document.querySelectorAll('.js-facebook-splash');
  const enabled = localStorage.getItem('fb-teaser-active');

  facebookTeaserLinks.forEach(($link) => {
    const $teaser = $link.closest('.social-media-teaser');
    const $image = $teaser.querySelector('.social-media-teaser__image');
    const $content = $teaser.querySelector('.social-media-teaser__content');

    // Toggle image and description text
    if (enabled) {
      $image.setAttribute('hidden', true);
      $content.setAttribute('hidden', true);
    } else {
      $image.removeAttribute('hidden');
      $content.removeAttribute('hidden');
    }

    // Toggle link
    const $innerSpan = $link.querySelector('.more-link__text');
    $innerSpan.innerText = enabled ? t('Facebook-Plugin deaktivieren') : t('Facebook-Plugin aktivieren');

    // Add or remove iFrame
    if (enabled) {
      const $moreLinkContainer = $link.parentNode;
      $moreLinkContainer.parentNode.insertBefore(h('iframe.social-media-teaser__iframe', {
        src: `https://www.facebook.com/plugins/likebox.php?href=${encodeURIComponent($link.href)}&width=295&height=300&colorscheme=light&show_faces=true&border_color&stream=false&header=false`,
        scrolling: 'no',
        frameboarder: '0',
        allowtransparency: 'true',
      }), $moreLinkContainer);
    } else {
      const $iframe = $teaser.querySelector('.social-media-teaser__iframe');
      if ($iframe) {
        $iframe.parentNode.removeChild($iframe);
      }
    }
  });
}

document.addEventListener('click', (event) => {
  if (getTarget(event.target, '.js-facebook-splash')) {
    event.preventDefault();

    if (localStorage.getItem('fb-teaser-active')) {
      localStorage.removeItem('fb-teaser-active');
    } else {
      localStorage.setItem('fb-teaser-active', true);
    }

    switchFacebookTeasers();
  }
});

// Init on page load
switchFacebookTeasers();
loadTweets();
