import h from 'hyperscript';
import classNames from 'classnames';
import generateIcon from 'components/_particles/icon/_icon';

export default function iconButton({
  tag, type, title, icon, link, disabled = false, classes = false,
}) {
  return h(`${(link && 'a') || tag || 'button'}`, {
    className: classNames(
      'button',
      'button--primary',
      'button--small',
      classes && classes,
    ),
    type: type || (!link && 'button'),
    href: link,
    title,
    disabled,
  }, h('.button__icon-wrap', generateIcon({ icon, classes: ['button__icon'] })));
}
