// @flow
import * as React from 'react';
import Fetch from '../../../javascripts/utils/Fetch';
import Spinner from '../../atoms/spinner/Spinner';
import Image from '../../atoms/image/Image';
import Headline from '../../atoms/headline/Headline';
import SimpleList from '../../molecules/simple-list/SimpleList';
import IconLinkGroup from '../../molecules/icon-link-group/IconLinkGroup';
import Link from '../../atoms/link/Link';
import Dialog from '../../_particles/dialog/Dialog';
import { t } from '../../../javascripts/utils/withTranslation';

type Props = {
  apiUrl: string,
  onClose: () => void,
  dialogParent: Element,
};

type Item = {
  text: string,
  link?: string,
  external?: boolean,
  download?: string | boolean,
};

type Section = {
  title: string,
  items: Array<Item>,
};

type Data = {
  imageSrc?: string,
  imageWidth?: number,
  imageHeight?: number,
  name: string,
  role?: string,
  slogan?: string,
  telephone?: string,
  contact?: string,
  profile?: string,
  socialMedia?: { [string]: string },
  sections?: Array<Section>,
};

type SocialMediaLabels = { [string]: string };

const socialMediaLabels: SocialMediaLabels = {
  facebook: 'Facebook',
  'x.com': 'x.com',
  xing: 'XING',
  googleplus: 'Google+',
  linkedin: 'LinkedIn',
};

export default class PersonDialog extends React.Component<Props> {
  renderSection = (section: Section, index: number) => {
    // Prepare items
    const items = section.items.map((item: Item) => {
      const {
        text,
        link,
        external = false,
        download = false,
      } = item;

      return link ? <Link link={link} download={download} external={external}>{text}</Link> : text;
    });

    // Render section
    return (
      <div className="person-dialog__list" key={index}>
        <div className="person-dialog__list-headline">
          <Headline level={2} size={4}>{section.title}</Headline>
        </div>

        <SimpleList key={index} items={items} />
      </div>
    );
  };

  renderContent = (data: Data) => {
    const iconLinks = [];

    if (data.telephone) {
      iconLinks.push({
        icon: 'phone-outline',
        link: `tel:${data.telephone}`,
        children: data.telephone,
      });
    }

    if (data.contact) {
      iconLinks.push({
        icon: 'message',
        link: data.contact,
        children: t('Nachricht schreiben'),
      });
    }

    if (data.profile) {
      iconLinks.push({
        icon: 'person',
        link: data.profile,
        children: t('Zum Profil'),
      });
    }

    if (data.socialMedia) {
      Object.entries(data.socialMedia)
        // $FlowFixMe
        .forEach(([service, link]: [string, string]) => iconLinks.push({
          icon: service,
          link,
          children: socialMediaLabels[service],
          target: '_blank',
          rel: 'noopener noreferrer',
        }));
    }

    return (
      <React.Fragment>
        <div className="person-dialog__inner">
          <div className="person-dialog__details">
            {data.imageSrc && <div className="person-dialog__image">
              <Image
                src={data.imageSrc}
                alt={data.name}
                width={data.imageWidth || 228}
                height={data.imageHeight || 170}
              />
            </div>}

            <h1 className="person-dialog__name">
              {data.name}
              {data.role && <React.Fragment>
                <span className="u-hidden-visually">: </span>
                <span className="person-dialog__role">{data.role}</span>
              </React.Fragment>}
            </h1>

            {data.slogan && <p className="person-dialog__slogan">{data.slogan}</p>}

            {iconLinks.length > 0 && <div className="person-dialog__links">
              <IconLinkGroup links={iconLinks} />
            </div>}
          </div>

          <div className="person-dialog__lists">
            {data.sections && data.sections.length && data.sections.map(this.renderSection)}
          </div>
        </div>

        <div className="person-dialog__puffer" />
      </React.Fragment>
    );
  };

  render() {
    return (
      <Dialog
        onClose={this.props.onClose}
        dialogParent={this.props.dialogParent}
        closeButton={true}
      >
        <div className="person-dialog">
          <Fetch apiUrl={this.props.apiUrl}>
            {({ loading, data, error }) => {
              if (loading) {
                return <div className="person-dialog__loading">
                  <Spinner label={t('Person wird geladen...')} />
                </div>;
              }

              if (error) {
                return <div className="person-dialog__loading">
                  <Spinner icon="error" label={t('Person konnte nicht geladen werden.')} />
                </div>;
              }

              if (data) {
                return this.renderContent(data);
              }

              return null;
            }}
          </Fetch>
        </div>
      </Dialog>
    );
  }
}
