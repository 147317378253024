const environment = window.bst || {};

export const ASSET_BASE_URL = environment.ASSET_BASE_URL || '/';

export const ICON_SPRITE_URL = environment.ICON_SPRITE_URL || '/icons/icons.svg';

export const TITLE = environment.TITLE || 'Bertelsmann Stiftung';

export const LANG = document.documentElement.lang || 'de';

export const MAPBOX_KEY = environment.MAPBOX_KEY || null;

export const TWITTER_SHARE_ACCOUNT = environment.TWITTER_SHARE_ACCOUNT || 'BertelsmannSt';

export const MEDIA_QUERIES = {
  s: '(min-width: 0px)',
  m: '(min-width: 36.25em)',
  l: '(min-width: 51.875em)',
  xl: '(min-width: 68.75em)',
};
