import { lory } from '@rsm/allfarblori';
import h from 'hyperscript';
import { generateIcon as icon } from 'components/_particles/icon/Icon';
import { t } from 'javascripts/utils/withTranslation';

export default class TabBox {
  constructor($tabBox) {
    this.$tabBox = $tabBox;
    this.$tabBox.addEventListener('selectTab', this.onSelectTab.bind(this));
    this.$sliderWrap = $tabBox.querySelector('.tab-box__publications-wrap');

    // Init publication slider if needed
    const $currentPanel = this.$tabBox.querySelector('.tab-box__panel:not([hidden])');
    if ($currentPanel.querySelector('.tab-box__publications')) {
      this.initPublicationsSlider($currentPanel);
    }
  }

  onSelectTab(event) {
    // Get current panel
    const $currentPanel = this.$tabBox.querySelector('.tab-box__panel:not([hidden])');

    // Destory slider
    if ($currentPanel.lorySlider) {
      $currentPanel.lorySlider.destroy();
      delete $currentPanel.lorySlider;
    }

    // Hide current panel
    $currentPanel.removeAttribute('tabindex');
    $currentPanel.setAttribute('hidden', true);

    // Find new panel
    const $headline = this.$tabBox.querySelector(event.target.getAttribute('href'));
    const $panel = $headline.closest('.tab-box__panel');

    // Make panel visible
    $panel.removeAttribute('hidden');
    $headline.focus();

    // Enable publication slider if needed
    if ($panel.querySelector('.tab-box__publications')) {
      this.initPublicationsSlider($panel);
    }
  }

  syncDotsNavigation($panel, activeSlide) {
    $panel.querySelectorAll('.tab-box__publications-dot').forEach(($dot, index) => {
      $dot.classList.remove('tab-box__publications-dot--active');
      if (activeSlide === index) {
        $dot.classList.add('tab-box__publications-dot--active');
      }
    });
  }

  initPublicationsSlider($panel) {
    const $sliderWrap = $panel.querySelector('.tab-box__publications-wrap');
    const $buttonsWrap = h('.tab-box__publications-nav');
    $sliderWrap.appendChild($buttonsWrap);

    // Add prev buttons
    const $prevButton = h('button.button.button--small.button--primary.tab-box__publications-nav-button.tab-box__publications-nav-button--prev', {
      type: 'button',
      title: t('Vorherige Publikation'),
      disabled: true,
      tabIndex: '-1',
    }, icon({
      icon: 'left',
      className: 'button__icon',
    }), h('span.u-hidden-visually', t('Vorherige Publikation')));
    $buttonsWrap.appendChild($prevButton);

    // Add next buttons
    const $nextButton = h('button.button.button--small.button--primary.tab-box__publications-nav-button.tab-box__publications-nav-button--next', {
      type: 'button',
      title: t('Nächste Publikation'),
      tabIndex: '-1',
    }, icon({
      icon: 'right',
      className: 'button__icon',
    }), h('span.u-hidden-visually', t('Nächste Publikation')));
    $buttonsWrap.appendChild($nextButton);

    // Reset scrolling before init
    const onBeforeInit = () => {
      this.$sliderWrap.scrollLeft = 0;
    };
    $panel.addEventListener('before.lory.init', onBeforeInit);

    // Init slider
    // eslint-disable-next-line no-param-reassign
    $panel.lorySlider = lory($panel, {
      classNameFrame: 'tab-box__publications-wrap',
      classNameItem: 'tab-box__publication',
      classNameSlideContainer: 'tab-box__publications',
      classNamePrevCtrl: 'tab-box__publications-nav-button--prev',
      classNameNextCtrl: 'tab-box__publications-nav-button--next',
      classNameDisabledPrevCtrl: 'tab-box__publications-nav-button--disabled',
      classNameDisabledNextCtrl: 'tab-box__publications-nav-button--disabled',
    });

    // Dots navigation
    const publications = $panel.querySelectorAll('.tab-box__publication');
    const countOfPublications = publications.length;
    const $dotsNavigation = h(
      '.tab-box__publications-dots',
      Array(countOfPublications).fill().map((a, index) => h(
        `button.tab-box__publications-dot${index === 0 ? '.tab-box__publications-dot--active' : ''}`,
        {
          type: 'button',
          title: t('Zur Publikation {index}', { index: index + 1 }),
          tabIndex: '-1',
          onclick(event) {
            event.preventDefault();
            $panel.lorySlider.slideTo(index);
          },
        },
        h('span.u-hidden-visually', t('Zur Publikation {index}', { index: index + 1 })),
      )),
    );
    $sliderWrap.appendChild($dotsNavigation);

    // On slide change
    const onSlideChange = (event) => {
      // Sync prev button
      if (event.detail.currentSlide === 0) {
        $prevButton.setAttribute('disabled', true);
      } else {
        $prevButton.removeAttribute('disabled');
      }

      // Sync next button
      if (event.detail.currentSlide === (countOfPublications - 1)) {
        $nextButton.setAttribute('disabled', true);
      } else {
        $nextButton.removeAttribute('disabled');
      }

      // Sync dots navigation
      this.syncDotsNavigation($panel, event.detail.currentSlide);
    };

    // On resize
    const onResize = () => this.syncDotsNavigation($panel, 0);

    // On focus
    const onFocus = (event) => {
      this.$sliderWrap.scrollLeft = 0;
      const $publication = event.target.closest('.tab-box__publication');
      const index = Array.prototype.indexOf.call(publications, $publication);
      $panel.lorySlider.slideTo(index);
    };

    // On destory
    const onDestroy = () => {
      // Deregister events
      $panel.removeEventListener('focusin', onFocus);
      $panel.removeEventListener('after.lory.slide', onSlideChange);
      $panel.removeEventListener('on.lory.resize', onResize);
      $panel.removeEventListener('after.lory.destroy', onDestroy);
      $panel.removeEventListener('before.lory.init', onBeforeInit);

      // Remove dots and button navigation
      $sliderWrap.removeChild($buttonsWrap);
      $sliderWrap.removeChild($dotsNavigation);

      // Remove style attribute
      $panel.querySelector('.tab-box__publications').removeAttribute('style');
    };

    // Register events
    $panel.addEventListener('focusin', onFocus);
    $panel.addEventListener('after.lory.slide', onSlideChange);
    $panel.addEventListener('on.lory.resize', onResize);
    $panel.addEventListener('after.lory.destroy', onDestroy);
  }
}

document.querySelectorAll('.tab-box').forEach($el => new TabBox($el));
