// @flow
import * as React from 'react';
import Copyright from '../../atoms/copyright/Copyright';

import type { ImageT } from './Lightbox';

type Props = {
  image: ImageT,
  numberOf: string | false,
};

type State = {
  largeWidth: number,
  largeHeight: number,
};

export default class CurrentImage extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.imageRef = React.createRef();
    this.captionRef = React.createRef();

    this.state = {
      largeWidth: 0,
      largeHeight: 0,
    };

    this.getImageDimensions();
  }

  getImageDimensions = () => {
    const {
      image: { link },
    } = this.props;

    const image = new Image();
    image.onload = (event) => {
      const { width, height } = event.target;
      this.setState({ largeWidth: width, largeHeight: height });
    };
    image.src = link;
  };

  updateImageDimensions = () => {
    const { height } = this.imageRef.current.parentNode.getBoundingClientRect();
    const { height: captionHeight } = this.captionRef.current.getBoundingClientRect();
    const imageMaxHeight = height - captionHeight - 30;
    this.imageRef.current.style['max-height'] = `${imageMaxHeight}px`;
  }

  componentDidMount() {
    this.updateImageDimensions();
    window.addEventListener('resize', this.updateImageDimensions);
    window.addEventListener('orientationchange', this.updateImageDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateImageDimensions);
    window.removeEventListener('orientationchange', this.updateImageDimensions);
  }

  render() {
    const { numberOf } = this.props;
    const {
      link, alt, caption, source,
    } = this.props.image;
    const { largeWidth, largeHeight } = this.state;

    return (
      <React.Fragment>
        <img
          className="lightbox__image"
          src={link}
          alt={alt}
          ref={this.imageRef}
          width={largeWidth}
          height={largeHeight}
        />
        <div className="lightbox__caption-box t-inverted" ref={this.captionRef}>
          {(caption || numberOf) && <span className="lightbox__caption">
            {caption && `${caption} `}{numberOf && `(${numberOf})`}
          </span>}
          {source && <Copyright text={source} />}
        </div>
      </React.Fragment>
    );
  }
}
