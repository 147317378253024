import Scrollable from 'javascripts/utils/scrollable';
import { MEDIA_QUERIES } from 'javascripts/constants';

/* eslint-disable no-unused-vars */
document.querySelectorAll('.info-bubbles').forEach(($infoBubbles) => {
  // Init scrollable
  const scrollableInstance = new Scrollable(
    $infoBubbles,
    $infoBubbles.querySelector('.info-bubbles__inner'),
    'info-bubbles__button',
  );
});
/* eslint-enable no-unused-vars */

// Init tooltips
document.querySelectorAll('.info-bubbles__item').forEach(($bubble, index, $bubbles) => {
  const $scrollbox = $bubble.closest('.info-bubbles__wrap');
  const $closeButton = $bubble.querySelector('.info-bubbles__tooltip-close');
  const mql = window.matchMedia(MEDIA_QUERIES.m);

  const tooltipOrientation = ($tooltip) => {
    // render left oriented tooltip if it doesn't fit into the container
    const { right: rightBox } = $scrollbox.getBoundingClientRect();
    const { right: rightTooltip } = $tooltip.getBoundingClientRect();

    if ((rightTooltip > rightBox) && mql.matches) {
      $tooltip.classList.add('info-bubbles__tooltip--left');
    } else {
      $tooltip.classList.remove('info-bubbles__tooltip--left');
    }
  };

  const closeAllTooltipsExeptTarget = ($target) => {
    $bubbles.forEach(($item) => {
      if ($item !== $target) {
        const $tooltip = $item.querySelector(`#${$item.getAttribute('aria-controls')}`);
        if ($tooltip) {
          $tooltip.classList.remove('info-bubbles__tooltip--show');
          $item.setAttribute('aria-expanded', 'false');
        }
      }
    });
  };

  const toggleTooltip = (event) => {
    const $target = event.target;
    const hidden = $target.getAttribute('aria-expanded') === 'false';
    const $tooltip = $target.querySelector(`#${$target.getAttribute('aria-controls')}`);

    if (event.type === 'click') {
      closeAllTooltipsExeptTarget($target);
    }

    // position the tooltip on desktop
    if ($tooltip && mql.matches) {
      const { left: leftBubble } = $target.getBoundingClientRect();
      const { left: leftParent } = $scrollbox.getBoundingClientRect();
      $tooltip.setAttribute('style', `left: ${leftBubble - leftParent}px;`);
    } else {
      $tooltip.removeAttribute('style');
    }

    if ($tooltip && event.type) {
      $target.setAttribute('aria-expanded', hidden.toString());

      if (hidden) {
        // show and calculate orientation
        tooltipOrientation($tooltip);
        $tooltip.classList.add('info-bubbles__tooltip--show');
      } else {
        // hide
        $tooltip.classList.remove('info-bubbles__tooltip--show');
      }
      // always remove orientation on mouseout
      if (event.type === 'mouseout') {
        $tooltip.classList.remove('info-bubbles__tooltip--left');
      }
    }
  };

  if (!mql.matches) {
    $bubble.addEventListener('click', toggleTooltip);
    if ($closeButton) {
      $closeButton.addEventListener('click', () => {
        closeAllTooltipsExeptTarget(null);
      });
    }
  } else {
    $bubble.addEventListener('mouseover', toggleTooltip);
    $bubble.addEventListener('mouseout', toggleTooltip);
  }

  document.addEventListener('resize', () => toggleTooltip({ target: $bubble }));

  // set initial tooltip position
  toggleTooltip({ target: $bubble });
});
