import h from 'hyperscript';
import mapboxgl from 'mapbox-gl/dist/mapbox-gl';
import MapboxLanguage from '@mapbox/mapbox-gl-language';
import { MAPBOX_KEY, MEDIA_QUERIES } from 'javascripts/constants';

mapboxgl.accessToken = MAPBOX_KEY;

const PADDING = 100;

const DISABLED_EVENTS = [
  'scrollZoom',
  'boxZoom',
  'dragRotate',
  'dragPan',
  'keyboard',
  'doubleClickZoom',
  'touchZoomRotate',
];

class Map {
  constructor($el) {
    this.$el = $el;
    this.$container = this.$el.querySelector('.map__container');
    this.$slides = this.$el.querySelectorAll('.map__box-slide');

    this.initConfiguration();
    this.initMap();
    this.initMarkers();
    this.initEvents();
  }

  initConfiguration() {
    this.configuration = JSON.parse(this.$el.querySelector('.map__configuration').innerHTML);
  }

  initMap() {
    this.map = new mapboxgl.Map({
      container: this.$container,
      style: 'mapbox://styles/rsmtech/cjuwh2rck077d1fokv4i4qr5b',
      attributionControl: false,
    });

    this.map.addControl(new mapboxgl.AttributionControl({ compact: true }), 'top-right');
    this.map.addControl(new MapboxLanguage({ defaultLanguage: 'de' }));

    this.map.once('style.load', () => {
      this.$el.querySelector('.mapboxgl-ctrl-bottom-left').innerHTML = '';
      this.$el.querySelector('.mapboxgl-ctrl-top-left').appendChild(
        h('.mapboxgl-ctrl',
          h('a.mapboxgl-ctrl-logo', {
            target: '_blank',
            href: 'https://www.mapbox.com/',
            rel: 'noopener',
            'aria-label': 'Mapbox logo',
          })),
      );

      this.initBounds();
    });

    DISABLED_EVENTS.forEach(event => this.map[event].disable());
  }

  initMarkers() {
    const $markerElement = (index) => {
      const $el = document.createElement('div');

      $el.id = `marker-${index}`;
      $el.className = 'map__marker';

      $el.addEventListener('click', () => {
        this.selectMarker(index);
      });

      return $el;
    };

    this.markers = this.configuration.markers.map(
      (marker, index) => new mapboxgl.Marker($markerElement(index))
        .setLngLat(marker.location)
        .addTo(this.map),
    );

    this.$slides.forEach(($slide) => {
      $slide.setAttribute('aria-hidden', 'true');
    });

    this.selectMarker(0);
  }

  selectMarker(id) {
    const markerId = `marker-${id}`;

    this.markers.forEach(m => m.getElement().classList.remove('map__marker--active'));
    this.$slides.forEach(s => s.setAttribute('aria-hidden', 'true'));

    const $marker = this.markers.find(m => m.getElement().id === markerId);
    $marker.getElement().classList.add('map__marker--active');

    const $slide = this.$el.querySelector(`.map__box-slide[id=${markerId}]`);
    $slide.setAttribute('aria-hidden', 'false');
  }

  initBounds() {
    this.map.fitBounds(this.configuration.markers.filter(m => m.boundary).map(m => m.location), {
      padding: {
        top: PADDING,
        bottom: PADDING,
        left: PADDING,
        right: PADDING + (window.matchMedia(MEDIA_QUERIES.l).matches ? 500 : 0),
      },
    });
  }

  initEvents() {
    window.addEventListener('resize', () => this.initBounds());
  }
}

document.querySelectorAll('.map').forEach($el => new Map($el));
