import ReactDOM from 'react-dom';
import React from 'react';
import h from 'hyperscript';
import getTarget from 'javascripts/utils/get-target';
import PersonDialog from './PersonDialog';

document.addEventListener('click', (event) => {
  const $target = getTarget(event.target, '.js-person-dialog');

  if ($target) {
    // Get API url
    const apiUrl = $target.getAttribute('data-api-url');

    if (apiUrl) {
      // Prevent navigation
      event.preventDefault();

      // Prepare mount point
      const $dialogParent = h('#person-dialog');
      const $mountPoint = h('#person-dialog-mountpoint');
      const $wrapper = h('div', $dialogParent, $mountPoint);
      document.body.appendChild($wrapper);

      // Render dialog
      ReactDOM.render(<PersonDialog dialogParent={$dialogParent} apiUrl={apiUrl} onClose={() => {
        ReactDOM.unmountComponentAtNode($mountPoint);
        $wrapper.parentNode.removeChild($wrapper);
        $target.focus();
      }} />, $mountPoint);
    }
  }
});
