import Scrollable from 'javascripts/utils/scrollable';

/* eslint-disable no-unused-vars */
document.querySelectorAll('.js-fact-bubbles').forEach(($factBubbles) => {
  // Init scrollable
  const scrollableInstance = new Scrollable(
    $factBubbles,
    $factBubbles.querySelector('.fact-bubbles__inner'),
    'fact-bubbles__button',
  );
});
/* eslint-enable no-unused-vars */
