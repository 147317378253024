// @flow
import * as React from 'react';
import classNames from 'classnames';

type Props = {
  children: string | React.Node,
  link: string,
  type?: string,
  internal?: boolean,
  external?: boolean,
  download?: string | boolean,
  className?: string,
};

const Link = (props: Props) => {
  const classes = classNames(
    'link',
    {
      'link--internal': props.internal || (!props.download && !props.external),
      'link--external': props.external,
      'link--download': props.download,
    },
    props.className,
  );

  return (
    <a
      href={props.link}
      className={classes}
      type={props.type}
      rel={(props.external && 'noopener noreferrer') || null}
      target={((props.external || props.download) && '_blank') || null}
      download={props.download}
    >
      {props.children}
    </a>
  );
};

export default Link;
