// @flow
import * as React from 'react';
import classNames from 'classnames';

import Icon from '../../_particles/icon/Icon';

export type Link = {
  link?: string,
  square?: boolean,
  stacked?: boolean,
  small?: boolean,
  reversed?: boolean,
  type?: 'button' | 'submit',
  className?: string,
  rel?: string,
  target?: string,
  icon: string,
  children: string,
  attr?: Object,
  onClick?: (event: SyntheticEvent<HTMLButtonElement>) => any,
};

export default class IconLink extends React.Component<Link> {
  render() {
    const {
      link,
      children,
      square,
      stacked,
      small,
      reversed,
      className,
      type,
      icon,
      onClick,
      rel,
      target,
      attr,
    } = this.props;

    const Tag = link ? 'a' : 'button';

    const iconLinkClassName = classNames(
      {
        'icon-link': true,
        'icon-link--square': square,
        'icon-link--stacked': stacked,
        'icon-link--reversed': reversed,
        'icon-link--small': small,
      },
      className,
    );

    const attributes = {
      type: type || (!link ? 'button' : undefined),
      className: iconLinkClassName,
      href: link,
      rel,
      target,
      onClick,
      ...attr,
    };

    return (
      <Tag {...attributes}>
        <span className="icon-link__button">
          <Icon icon={icon} className="icon-link__icon" />
        </span>
        <span className="icon-link__text">{children}</span>
      </Tag>
    );
  }
}
