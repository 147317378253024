// @flow
import * as React from 'react';
import { Swipeable } from 'react-swipeable';
import CurrentImage from './CurrentImage';
import Icon from '../icon/Icon';
import type { ImageT } from './Lightbox';
import { t } from '../../../javascripts/utils/withTranslation';

type Props = {
  currentImage: number,
  images: Array<ImageT>,
  onNextImageClick: Function,
  onPrevImageClick: Function,
};

export default class ImageSlider extends React.Component<Props> {
  componentDidMount() {
    document.addEventListener('keydown', this.handleKeyPress);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyPress);
  }

  handleKeyPress = (event: KeyboardEvent) => {
    const { onNextImageClick, onPrevImageClick } = this.props;

    if (event.key === 'ArrowLeft') {
      onPrevImageClick();
    } else if (event.key === 'ArrowRight') {
      onNextImageClick();
    }
  };

  render() {
    const {
      currentImage, images, onPrevImageClick, onNextImageClick,
    } = this.props;
    const numberOf = `${currentImage + 1}/${images.length}`;

    return (
      <div className="lightbox__image-slider">
        {images.length > 1 && (
          <button
            className="lightbox__nav-button lightbox__nav-button--prev"
            type="button"
            onClick={onPrevImageClick}
            aria-label={t('Vorheriges Foto anzeigen')}
          >
            <Icon className="lightbox__nav-icon" viewBox="0 0 70 200" icon="slim-arrow" />
          </button>
        )}

        <Swipeable className="lightbox__current-image" delta={100} onSwiped={({ dir }) => {
          if (dir === 'Left') {
            onNextImageClick();
          }

          if (dir === 'Right') {
            onPrevImageClick();
          }
        }}>
          <CurrentImage
            key={currentImage}
            image={images[currentImage]}
            numberOf={images.length > 1 && numberOf}
          />
        </Swipeable>

        {images.length > 1 && (
          <button
            className="lightbox__nav-button lightbox__nav-button--next"
            type="button"
            onClick={onNextImageClick}
            aria-label={t('Nächstes Foto anzeigen')}
          >
            <Icon className="lightbox__nav-icon" viewBox="0 0 70 200" icon="slim-arrow" />
          </button>
        )}
      </div>
    );
  }
}
