import truncatedContainer from 'components/molecules/truncated-container/truncated-container';

document.querySelectorAll('.tabbed-teasers').forEach(($tabbedTeasers) => {
  $tabbedTeasers.addEventListener('selectTab', (event) => {
    // Get current panel
    const $currentPanel = $tabbedTeasers.querySelector('.tabbed-teasers__panel:not([hidden])');

    // Hide current panel
    $currentPanel.removeAttribute('tabindex');
    $currentPanel.setAttribute('hidden', true);

    // Find new panel
    const $headline = $tabbedTeasers.querySelector(event.target.getAttribute('href'));
    const $panel = $headline.closest('.tabbed-teasers__panel');

    // Make panel visible
    $panel.removeAttribute('hidden');

    // Refresh lory
    const $teaserSlider = $panel.querySelector('.teaser-slider');
    if ($teaserSlider && $teaserSlider.teaserSlider && $teaserSlider.teaserSlider.lory) {
      $teaserSlider.teaserSlider.lory.reset();
    }

    // Refresh truncated container
    $panel
      .querySelectorAll('.truncated-container')
      .forEach($truncatedContainer => truncatedContainer($truncatedContainer));

    // Focus
    const { repeated = false } = event.detail;
    if (!repeated) $headline.focus();
  });

  // Resend events
  const $tabBar = $tabbedTeasers.querySelector('.tab-bar');
  $tabBar.dispatchEvent(new CustomEvent('resendEvents', {
    bubbles: true,
  }));
});
