import ReactDOM from 'react-dom';
import React from 'react';
import h from 'hyperscript';
import getTarget from 'javascripts/utils/get-target';
import { MEDIA_QUERIES } from 'javascripts/constants';
import HeaderToggleButton from './HeaderToggleButton';
import HeaderNavigationDialogDesktop from './desktop/HeaderNavigationDialog';
import HeaderNavigationDialogMobile from './mobile/HeaderNavigationDialog';

// Toggle button
let headerToggleButton = null;
function onMediaQueryChange(mq) {
  const isDesktop = mq.matches;

  // Deinit on desktop
  if (isDesktop && headerToggleButton) {
    headerToggleButton.deconstructor();
    headerToggleButton = null;

  // Init on mobile
  } else if (!isDesktop && !headerToggleButton) {
    const $toggleButton = document.querySelector('#header-toggle');
    if ($toggleButton) {
      headerToggleButton = new HeaderToggleButton($toggleButton);
    }
  }
}

// Init media query listener
const mql = window.matchMedia(MEDIA_QUERIES.l);
mql.addListener(onMediaQueryChange);
onMediaQueryChange(mql);

// Click handler
document.addEventListener('click', (event) => {
  const $target = getTarget(event.target, '.js-navigation-dialog');

  if ($target) {
    // Prevent navigation
    event.preventDefault();

    if (mql.matches) {
      const items = [...document.querySelectorAll('.header__navigation-item-wrap')].map(($navItem) => {
        const $link = $navItem.querySelector('.header__navigation-item');

        return {
          title: $link.textContent,
          link: $link.href,
          sectionName: $link.getAttribute('data-section-name'),
          sectionSearch: $link.getAttribute('data-section-search'),
          sectionButton: $link.getAttribute('data-section-button'),
          items: [...$navItem.querySelectorAll('.header__subnavigation-item-wrap--parent')].map(($item) => {
            const $itemLink = $item.querySelector('.header__subnavigation-item');

            return {
              title: $itemLink.textContent,
              link: $itemLink.href,
              highlight: $itemLink.getAttribute('data-highlight'),
              sectionName: $itemLink.getAttribute('data-section-name'),
              sectionSearch: $itemLink.getAttribute('data-section-search'),
              sectionButton: $itemLink.getAttribute('data-section-button'),
              items: [...$item.querySelectorAll('.header__subnavigation-list .header__subnavigation-item')].map($subItem => ({
                title: $subItem.textContent,
                link: $subItem.href,
              })),
            };
          }),
        };
      });

      // Prepare mount point
      const $dialogParent = h('#header-dialog.dialog--header');
      const $mountPoint = h('#header-dialog-mountpoint');
      const $wrapper = h('div', $dialogParent, $mountPoint);
      document.body.appendChild($wrapper);

      // Render dialog
      ReactDOM.render(<HeaderNavigationDialogDesktop
        dialogParent={$dialogParent}
        items={items}
        openIndex={parseInt($target.getAttribute('data-section-index'), 10)}
        onClose={() => {
          document.body.style.overflow = '';
          ReactDOM.unmountComponentAtNode($mountPoint);
          $wrapper.parentNode.removeChild($wrapper);
          $target.focus();
        }}
      />, $mountPoint);
    } else {
      // Prevent navigation
      event.preventDefault();

      // Build tree
      const items = [];
      $target.parentNode
        .querySelectorAll('.header__subnavigation-item-wrap--parent')
        .forEach(($item) => {
          // Prepare item
          const $link = $item.querySelector('.header__subnavigation-item');
          const item = {
            title: $link.textContent,
            link: $link.href,
            sectionName: $link.getAttribute('data-section-name'),
            sectionSearch: $link.getAttribute('data-section-search'),
            sectionButton: $link.getAttribute('data-section-button'),
            items: [],
          };

          // Add subitems
          $item
            .querySelectorAll('.header__subnavigation-list .header__subnavigation-item')
            .forEach(($subitem) => {
              item.items.push({
                title: $subitem.textContent,
                link: $subitem.href,
              });
            });

          // Add item to items collection
          items.push(item);
        });

      // Prepare mount point
      const $dialogParent = h('#header-dialog');
      const $mountPoint = h('#header-dialog-mountpoint');
      const $wrapper = h('div', $dialogParent, $mountPoint);
      document.body.appendChild($wrapper);

      // Render dialog
      ReactDOM.render(<HeaderNavigationDialogMobile
        dialogParent={$dialogParent}
        items={items}
        onClose={() => {
          ReactDOM.unmountComponentAtNode($mountPoint);
          $wrapper.parentNode.removeChild($wrapper);
          $target.focus();
        }}
      />, $mountPoint);
    }

    // etracker
    // eslint-disable-next-line
    _etracker.sendEvent(new et_ClickEvent('Hauptmenü', $target.querySelector('.header__navigation-item-inner').textContent));
  }
});
