// @flow
import * as React from 'react';
import Image from '../../atoms/image/Image';
import Lightbox from '../../_particles/lightbox/Lightbox';

export type ImageT = {
  src: string,
  link: string,
  alt?: string,
  caption?: string,
  source?: string,
  index: number,
  width: number,
  height: number,
};

type Props = {
  images: Array<ImageT>,
};

type State = {
  currentImage: ?number,
  isLightboxOpen: boolean,
};

export default class Gallery extends React.Component<Props, State> {
  state = {
    isLightboxOpen: false,
    currentImage: undefined,
    images: this.props.images,
  };

  handleImageClick = (currentImage: number) => {
    this.setState({ isLightboxOpen: true, currentImage });

    // etracker
    // eslint-disable-next-line
    _etracker.sendEvent(new et_ClickEvent('Bildergalerie öffnen', this.props.images[currentImage].alt));
  };

  handleCloseLightbox = () => {
    this.setState({ isLightboxOpen: false });
  };

  render() {
    const { images } = this.props;
    const { isLightboxOpen, currentImage } = this.state;

    return (
      <React.Fragment>
        <div className="gallery__inner">
          {images.map(image => (
            <a
              key={image.index}
              href={image.link}
              className="gallery__panel"
              onClick={(event) => {
                event.preventDefault();
                this.handleImageClick(image.index);
              }}
            >
              <Image src={image.src} width={image.width} height={image.height} alt={image.alt || ''} />
            </a>
          ))}
        </div>

        {isLightboxOpen && currentImage != null && (
          <Lightbox
            images={images}
            currentImage={currentImage}
            isLightboxOpen
            onClose={this.handleCloseLightbox}
          />
        )}
      </React.Fragment>
    );
  }
}
