// @flow
import * as React from 'react';
import Icon from '../../_particles/icon/Icon';

type Props = {
  text: string,
};

const Copyright = (props: Props) => (
  <span className="copyright">
    <Icon icon='camera' className='copyright__icon' viewBox="0 0 200 200" />
    <span className="copyright__text">{props.text}</span>
  </span>
);

export default Copyright;
