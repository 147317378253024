import ReactDOM from 'react-dom';
import React from 'react';
import h from 'hyperscript';
import getTarget from 'javascripts/utils/get-target';
import Dialog from 'components/_particles/dialog/Dialog';

const openDialogGraphsDialog = ($content, $trigger = null) => {
  // Prepare mount point
  const $dialogParent = h('#dialog-graphs-dialog');
  const $mountPoint = h('#dialog-graphs-dialog-mountpoint');
  const $wrapper = h('div', $dialogParent, $mountPoint);
  document.body.appendChild($wrapper);

  // Render dialog
  ReactDOM.render(<Dialog closeButton={true} dialogParent={$dialogParent} className="tiled-grid__dialog" onClose={() => {
    ReactDOM.unmountComponentAtNode($mountPoint);
    $wrapper.parentNode.removeChild($wrapper);

    if ($trigger) {
      $trigger.focus();
    }
  }}>
    <div className="dialog-graphs__dialog-inner" dangerouslySetInnerHTML={{ __html: $content.innerHTML }} />
  </Dialog>, $mountPoint);
};

// Open by click
document.addEventListener('click', (event) => {
  const $target = getTarget(event.target, '.dialog-graphs__button');

  if ($target && $target.getAttribute('aria-controls')) {
    const contentId = $target.getAttribute('aria-controls');

    if (document.getElementById(contentId)) {
      // Prevent navigation
      event.preventDefault();

      // Get content
      const $content = document.getElementById(contentId);

      // Open dialog
      openDialogGraphsDialog($content, $target);
    }
  }
});

export default openDialogGraphsDialog;
