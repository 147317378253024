import store from 'store/dist/store.modern';

const overlayBreaker = ($overlayBreaker) => {
  const cookieName = 'bst-overlayBreaker';

  // Check if cookie exists
  if (!store.get(cookieName)) {
    const visibleClass = 'overlay-breaker--visible';
    const bottomClass = 'overlay-breaker--bottom';
    const $close = $overlayBreaker.querySelector('.overlay-breaker__close');

    // Show breaker after 3 seconds
    setTimeout(() => {
      $overlayBreaker.classList.add(visibleClass);
    }, 3000);

    $close.addEventListener('click', () => {
      if ($overlayBreaker.classList.contains(visibleClass)) {
        $overlayBreaker.classList.remove(visibleClass);
      }

      // Set cookie
      store.set(cookieName, true);
    });

    // Scroll breaker to never hide imprint link
    window.onscroll = () => {
      const bodyHeight = document.body.offsetHeight;
      const scrollDistance = window.innerHeight + window.scrollY + 70;
      const hasBottomClass = $overlayBreaker.classList.contains(bottomClass);

      if ((scrollDistance >= bodyHeight) && !hasBottomClass) {
        $overlayBreaker.classList.add(bottomClass);
      } else if (scrollDistance < bodyHeight && hasBottomClass) {
        $overlayBreaker.classList.remove(bottomClass);
      }
    };
  }
};

document.querySelectorAll('.js-overlay-breaker')
  .forEach($overlayBreaker => overlayBreaker($overlayBreaker));
