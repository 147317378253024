import shareThis from 'share-this/dist/share-this';
import * as twitterSharer from 'share-this/dist/sharers/twitter';
import * as facebookSharer from 'share-this/dist/sharers/facebook';
import * as emailSharer from 'share-this/dist/sharers/email';

let selectionShare = false;

function onMediaQueryChange(mq) {
  const hasTouchInterface = mq.matches;

  // Init share this
  if (!hasTouchInterface && !selectionShare) {
    if (document.querySelectorAll('.js-share-this').length === 0) {
      return;
    }

    selectionShare = shareThis({
      selector: '.js-share-this',
      sharers: [
        twitterSharer,
        facebookSharer,
        emailSharer,
      ],
    });

    selectionShare.init();
  } else if (hasTouchInterface && selectionShare) {
    selectionShare.destroy();
    selectionShare = false;
  }
}

// Init media query listener
const mql = window.matchMedia('(pointer: coarse)');
mql.addListener(onMediaQueryChange);
onMediaQueryChange(mql);
