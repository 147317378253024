/* eslint-disable import/first */

// Dynamic public path
// eslint-disable-next-line
__webpack_public_path__ = window.bst.javascriptBase || '/javascripts/';

// Polyfills & shims
import '@babel/polyfill';
import 'focus-visible';
import 'whatwg-fetch';
import 'element-matches';
import 'element-closest';
import 'classlist.js';
import './shims/element-remove';
import './shims/focus-within';
import './shims/nodelist-foreach';
import './shims/datepicker';
import './shims/customevent';

// Global scripts (Sharing, print, etc.)
import './globals/share-this';
import './globals/print';
import './globals/dialog-root';
import './globals/popup';
import './globals/pdf';

// Components
// import 'components/<atoms|molecules|organisms>/<component-name>/<js-file>';

// Atoms
import 'components/atoms/checkbox/checkbox';
import 'components/atoms/table/table';
import 'components/atoms/animation/animation';

// Molecules
import 'components/molecules/tab-bar/tab-bar';
import 'components/molecules/figure/figure';
import 'components/molecules/list-filter/list-filter';
import 'components/molecules/social-media-teaser/social-media-teaser';
import 'components/molecules/form-group/form-group';
import 'components/molecules/highlight-teaser/highlight-teaser';
import 'components/molecules/truncated-container/truncated-container';
import 'components/molecules/quote-slider/quote-slider';
import 'components/molecules/fact-bubbles/fact-bubbles';
import 'components/molecules/anchor-bar/anchor-bar';
import 'components/molecules/button-overlay/button-overlay';
import 'components/molecules/map-select/map-select';
import 'components/molecules/overlay-breaker/overlay-breaker';
import 'components/molecules/matrix/matrix';

// Organisms
import 'components/organisms/accordion/accordion';
import 'components/organisms/tab-box/tab-box';
import 'components/organisms/header/header';
import 'components/organisms/person-dialog/person-dialog';
import 'components/organisms/share-dialog/share-dialog';
import 'components/organisms/headroom';
import 'components/organisms/slider/slider';
import 'components/organisms/teaser-slider/teaser-slider';
import 'components/organisms/gallery';
import 'components/organisms/tabbed-teasers/tabbed-teasers';
import 'components/organisms/list/list';
import 'components/organisms/change-breaker/change-breaker';
import 'components/organisms/organigram/organigram';
import 'components/organisms/event-form/event-form';
import 'components/organisms/faq-teasers/faq-teasers';
import 'components/organisms/tiled-grid/tiled-grid';
import 'components/organisms/iframe-embed/iframe-embed';
import 'components/organisms/simple-header/simple-header';
import 'components/organisms/dialog-graphs/dialog-graphs';
import 'components/organisms/map/map';
import 'components/organisms/simple-slider/simple-slider';
import 'components/organisms/condolence-dialog/condolence-dialog';
import 'components/organisms/info-bubbles/info-bubbles';
import 'components/organisms/person-slider/person-slider';
import 'components/organisms/video-list/video-list';
import 'components/organisms/fallback-header/fallback-header';
