import getTarget from 'javascripts/utils/get-target';

document.addEventListener('click', (event) => {
  const $target = getTarget(event.target, '.js-popup');

  if ($target) {
    event.preventDefault();

    const url = $target.href;
    const { name } = $target.dataset;

    if (url && name) {
      window.open(url, name, 'width=600, height=600');
    }
  }
});
