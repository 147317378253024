import ReactDOM from 'react-dom';
import React from 'react';
import h from 'hyperscript';
import getTarget from 'javascripts/utils/get-target';
import Dialog from 'components/_particles/dialog/Dialog';

document.addEventListener('click', (event) => {
  const $target = getTarget(event.target, '.form-group__info');

  if ($target) {
    // Prevent navigation
    event.preventDefault();

    // Get content
    const $content = $target.querySelector('.form-group__info-content');

    // Prepare mount point
    const $dialogParent = h('#form-group-dialog');
    const $mountPoint = h('#form-group-dialog-mountpoint');
    const $wrapper = h('div', $dialogParent, $mountPoint);
    document.body.appendChild($wrapper);

    // Render dialog
    ReactDOM.render(<Dialog closeButton={true} dialogParent={$dialogParent} onClose={() => {
      ReactDOM.unmountComponentAtNode($mountPoint);
      $wrapper.parentNode.removeChild($wrapper);
      $target.focus();
    }}>
      <div dangerouslySetInnerHTML={ { __html: $content.innerHTML } } />
    </Dialog>, $mountPoint);
  }
});
