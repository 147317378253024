// @flow
import * as React from 'react';
import classNames from 'classnames';

type Props = {
  level: number,
  size?: number | 'teaser' | 'kicker',
  id?: string,
  kicker?: string,
  kickerAttr?: { [string]: string },
  children: string,
};

const Headline = (props: Props) => {
  const {
    level, kicker, kickerAttr = {}, children, size, id,
  } = props;

  const { className, ...kickerAttrRest } = kickerAttr;

  const Tag = `h${Math.min(Math.max(level, 1), 6)}`;
  const headlineSize = size || level;
  const headlineClasses = `headline headline--${headlineSize}`;

  const kickerClasses = classNames({
    headline__kicker: true,
    [className]: className,
  });

  return (
    <Tag className={headlineClasses} id={id}>
      {kicker && (
        <span className={kickerClasses} {...kickerAttrRest}>
          {kicker}
          <span className="u-hidden-visually">: </span>
        </span>
      )}
      {children}
    </Tag>
  );
};

export default Headline;
