// @flow
import * as React from 'react';
import classNames from 'classnames';
import Icon from '../../_particles/icon/Icon';

type Props = {
  link?: string,
  children?: string,
  disabled?: boolean,
  style?: 'primary' | 'secondary',
  small?: boolean,
  outline?: boolean,
  type?: 'button' | 'submit',
  onClick?: (event: SyntheticEvent<HTMLButtonElement>) => any,
  icon?: string,
  iconTitle?: string,
  className?: string,
};

const Button = (props: Props) => {
  const {
    link,
    children,
    style,
    small,
    outline,
    type,
    icon,
    className,
    onClick,
    disabled,
    iconTitle,
  } = props;

  const Tag = link ? 'a' : 'button';

  const classes = classNames(
    {
      button: true,
      [`button--${style || 'primary'}`]: true,
      'button--small': small,
      'button--outline': outline,
    },
    className,
  );

  const attributes = {
    href: link,
    type: type || (!link ? 'button' : undefined),
    onClick,
    disabled,
  };

  return (
    <Tag className={classes} {...attributes}>
      {icon && <Icon icon={icon} title={iconTitle} className="button__icon" />}
      {children && <span className="button__text">{children}</span>}
    </Tag>
  );
};

export default Button;
