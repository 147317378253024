import getTarget from 'javascripts/utils/get-target';
import { t } from 'javascripts/utils/withTranslation';

export default class EventForm {
  constructor($eventForm) {
    this.$eventForm = $eventForm;
    this.isSubmitted = false;

    // Check if content needs to be hidden/shown
    this.$eventForm
      .querySelectorAll('.checkbox__input[aria-controls], .radio__input[aria-controls]')
      .forEach($checkbox => this.toggleRadioOrCheckbox($checkbox));

    this.toggleFieldsDisabledAttribute();

    // Add events
    this.$eventForm.addEventListener('change', this.onCheckboxChange.bind(this));
    this.$eventForm.addEventListener('change', this.onRadioChange.bind(this));
    this.$eventForm.addEventListener('submit', this.onSubmit.bind(this));

    // Fill out fields
    const $data = this.$eventForm.querySelector('.event-form__data');
    if ($data) {
      try {
        const data = JSON.parse($data.innerHTML);

        Object.keys(data).forEach((key) => {
          // Fill out inputs/selects
          const $input = document.getElementById(`input${key}`);
          if ($input) {
            $input.value = data[key];
          }

          // Radio buttons/checkboxes
          const $radioOrCheckbox = document.getElementById(`${key}${data[key]}`);
          if ($radioOrCheckbox) {
            $radioOrCheckbox.setAttribute('checked', true);
          }
        });
      } catch (e) {
        // No error reporting
      }
    }

    // Check for programm validity
    this.checkForProgrammValidity();
  }

  toggleFieldsDisabledAttribute() {
    this.$eventForm
      .querySelectorAll('input, textarea, select')
      .forEach(($field) => {
        const $accordion = $field.closest('.accordion');
        const isVisible = $accordion ? $accordion.offsetHeight !== 0 : $field.offsetHeight !== 0;

        if ($field.hasAttribute('type') && $field.getAttribute('type') === 'hidden') {
          return;
        }

        if (isVisible) {
          $field.removeAttribute('disabled');
        } else {
          $field.setAttribute('disabled', true);
        }
      });
  }

  onSubmit(event) {
    if (this.isSubmitted) {
      event.preventDefault();
    }

    const $submit = this.$eventForm.querySelector('.button[type=submit]');
    $submit.setAttribute('disabled', true);
    this.isSubmitted = true;
  }

  onCheckboxChange(event) {
    this.checkForProgrammValidity();

    const $checkbox = getTarget(event.target, '.checkbox__input[aria-controls]');

    if ($checkbox) {
      this.toggleRadioOrCheckbox($checkbox);
    }
  }

  onRadioChange(event) {
    const $target = getTarget(event.target, '.radio__input');

    if ($target) {
      const $radioGroup = $target.closest('.form-group');

      if ($radioGroup) {
        const visibleTargets = [];

        $radioGroup.querySelectorAll('.radio__input[aria-controls]').forEach(($radio) => {
          this
            .toggleRadioOrCheckbox($radio, visibleTargets)
            .forEach(target => visibleTargets.push(target));
        });
      }
    }
  }

  checkForProgrammValidity() {
    // Check if at least one programm item is selected
    const $programmCheckboxes = this.$eventForm.querySelectorAll('.event-form__programm .checkbox__input');
    const $programmCheckboxesSelected = this.$eventForm.querySelectorAll('.event-form__programm .checkbox__input:checked');

    if ($programmCheckboxes.length > 0 && $programmCheckboxesSelected.length === 0) {
      $programmCheckboxes[0].setCustomValidity(t('Bitte wählen Sie mindestens einen Programmpunkt.'));
    } else if ($programmCheckboxes.length > 0) {
      $programmCheckboxes[0].setCustomValidity('');
    }
  }

  toggleRadioOrCheckbox($radioOrCheckbox, excludeTargets = []) {
    const targets = $radioOrCheckbox.getAttribute('aria-controls').split(' ');
    const visibleTargets = [];

    targets.forEach((target) => {
      const $target = document.getElementById(target);

      if ($target && !excludeTargets.includes(target)) {
        const isVisible = this.toggle($target, $radioOrCheckbox.checked);

        if (isVisible) {
          visibleTargets.push(target);
        }
      }
    });

    return visibleTargets;
  }

  toggle($target, toggle) {
    const isRevert = $target.getAttribute('data-toggle') === 'revert';
    let isVisible;

    if (toggle) {
      if (isRevert) {
        $target.setAttribute('hidden', true);
        isVisible = false;
      } else {
        $target.removeAttribute('hidden');
        isVisible = true;
      }
    } else if (isRevert) {
      $target.removeAttribute('hidden');
      isVisible = true;
    } else {
      $target.setAttribute('hidden', true);
      isVisible = false;
    }

    this.toggleFieldsDisabledAttribute();

    return isVisible;
  }
}

document.querySelectorAll('.event-form').forEach($eventForm => new EventForm($eventForm));
