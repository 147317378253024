import ReactDOM from 'react-dom';
import React from 'react';
import h from 'hyperscript';
import getTarget from 'javascripts/utils/get-target';
import ShareDialog from './ShareDialog';

document.addEventListener('click', (event) => {
  const $target = getTarget(event.target, '.js-share-dialog');

  if ($target) {
    // Prevent navigation
    event.preventDefault();

    // Share URL
    const { url, title, twitterAccount } = $target.dataset;

    // Prepare mount point
    const $dialogParent = h('#share-dialog');
    const $mountPoint = h('#share-dialog-mountpoint');
    const $wrapper = h('div', $dialogParent, $mountPoint);
    document.body.appendChild($wrapper);

    // Render dialog
    ReactDOM.render(<ShareDialog
      url={url}
      title={title}
      twitterAccount={twitterAccount}
      dialogParent={$dialogParent}
      onClose={() => {
        ReactDOM.unmountComponentAtNode($mountPoint);
        $wrapper.parentNode.removeChild($wrapper);
        $target.focus();
      }}
    />, $mountPoint);
  }
});
