export default function getIndexForElement($elToCheck) {
  let k = -1;
  let $el = $elToCheck;

  while ($el) {
    if ('previousElementSibling' in $el) {
      $el = $el.previousElementSibling;
      k += 1;
    } else {
      k -= 1;
      break;
    }
  }

  return k;
}
