import iconButton from 'components/atoms/button/button-small';
import throttle from 'javascripts/utils/throttle';
import h from 'hyperscript';
import scroll from 'scroll';

export default class Scrollable {
  constructor($container, $scrollContainer, prefix = 'fact-bubbles__button') {
    // Init
    this.$scrollContainer = $scrollContainer;
    this.$container = $container;
    this.prefix = prefix;

    // State
    this.leftVisible = false;
    this.rightVisible = false;

    // Generate buttons
    this.$left = iconButton({
      icon: 'hook-left', title: 'Nach links scrollen', large: true, floating: true,
    });
    this.$right = iconButton({
      icon: 'hook-right', title: 'Nach rechts scrollen', large: true, floating: true,
    });

    // Don't allow focus
    this.$left.setAttribute('tabindex', '-1');
    this.$right.setAttribute('tabindex', '-1');

    // Insert left button into DOM with wrapper
    this.$leftWrapper = h(`.${this.prefix}.${this.prefix}--left.${this.prefix}--disabled`, this.$left);
    this.$container.appendChild(this.$leftWrapper);

    // Insert right button into DOM with wrapper
    this.$rightWrapper = h(`.${this.prefix}.${this.prefix}--right.${this.prefix}--disabled`, this.$right);
    this.$container.appendChild(this.$rightWrapper);

    // Move on click
    this.$left.addEventListener('click', () => this.move('left'));
    this.$right.addEventListener('click', () => this.move('right'));

    // Add scroll listener
    this.scrollListenerBinded = throttle(this.scroll.bind(this));
    this.$scrollContainer.addEventListener('scroll', this.scrollListenerBinded, { passive: true });

    // Add resize and orientationchange
    window.addEventListener('resize', this.scrollListenerBinded);
    window.addEventListener('orientationchange', this.scrollListenerBinded);

    // Maybe show buttons by faking one scroll event
    this.scroll();
  }

  deconstructor() {
    // Reset scroll position
    this.$scrollContainer.scrollLeft = 0;

    // Remove buttons
    this.$rightWrapper.parentNode.removeChild(this.$rightWrapper);
    this.$leftWrapper.parentNode.removeChild(this.$leftWrapper);

    // Remove events
    this.$scrollContainer.removeEventListener('scroll', this.scrollListenerBinded);
    window.removeEventListener('resize', this.scrollListenerBinded);
    window.removeEventListener('orientationchange', this.scrollListenerBinded);
  }

  move(direction) {
    if (direction === 'right') {
      scroll.left(
        this.$scrollContainer,
        this.$scrollContainer.scrollLeft + (document.body.clientWidth / 2),
        { duration: 200 },
      );
    } else if (direction === 'left') {
      scroll.left(
        this.$scrollContainer,
        this.$scrollContainer.scrollLeft - (document.body.clientWidth / 2),
        { duration: 200 },
      );
    }
  }

  scroll() {
    window.requestAnimationFrame(() => {
      // Viewport
      const { offsetWidth, scrollWidth, scrollLeft } = this.$scrollContainer;

      // Calcutate
      const toRight = scrollWidth > offsetWidth && (scrollWidth - offsetWidth) !== scrollLeft;
      const toLeft = scrollLeft > 0;

      // Show right button
      if (toRight && !this.rightVisible) {
        this.$rightWrapper.classList.remove(`${this.prefix}--disabled`);
        this.rightVisible = true;
      } else if (!toRight && this.rightVisible) {
        this.$rightWrapper.classList.add(`${this.prefix}--disabled`);
        this.rightVisible = false;
      }

      // Show left button
      if (toLeft && !this.leftVisible) {
        this.$leftWrapper.classList.remove(`${this.prefix}--disabled`);
        this.leftVisible = true;
      } else if (!toLeft && this.leftVisible) {
        this.$leftWrapper.classList.add(`${this.prefix}--disabled`);
        this.leftVisible = false;
      }
    });
  }
}
