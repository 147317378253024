import toggler from 'javascripts/utils/toggler';

const $menuButton = document.querySelector('.fallback-header__menu-btn');
if ($menuButton) {
  toggler($menuButton, {
    focusTrap: {
      class: 'fallback-header__navigation',
    },
    addBodyClass: true,
    disableBodyScroll: true,
  });
}
