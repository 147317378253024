// @flow
export type MediaQuery = {
  name: string,
  query: mixed,
  matches?: boolean,
};

export type MediaQueries = Array<MediaQuery>;

export type CallbackValue = {
  mediaQueries: MediaQueries,
  currentMediaQuery: ?MediaQuery,
};

export default function mediaQueryWatcher(
  mediaQueries: MediaQueries,
  onUpdate: (mediaQueries: CallbackValue) => void,
): CallbackValue {
  const mediaQueryMatches: { [string]: boolean } = {};

  const mergeMatches: () => MediaQueries = () => mediaQueries.map(mq => (
    { ...mq, matches: mediaQueryMatches[mq.name] }
  ));

  const getWinner: () => ?MediaQuery = () => mergeMatches().find(mq => mq.matches);

  mediaQueries.forEach((mq) => {
    // Init media query
    const mql = window.matchMedia(mq.query);

    // Add listener on updates
    mql.addListener((e) => {
      mediaQueryMatches[mq.name] = e.matches;

      onUpdate({
        mediaQueries: mergeMatches(),
        currentMediaQuery: getWinner(),
      });
    });

    // Set current value
    mediaQueryMatches[mq.name] = mql.matches;
  });

  return {
    mediaQueries: mergeMatches(),
    currentMediaQuery: getWinner(),
  };
}
