import ReactDOM from 'react-dom';
import React from 'react';
import h from 'hyperscript';
import getTarget from 'javascripts/utils/get-target';
import Dialog from 'components/_particles/dialog/Dialog';

document.addEventListener('click', (event) => {
  const $target = getTarget(event.target, '.js-organigram-dialog');

  if ($target && $target.getAttribute('aria-controls')) {
    const contentId = $target.getAttribute('aria-controls');

    if (document.getElementById(contentId)) {
      // Prevent navigation
      event.preventDefault();

      // Get content
      const $content = document.getElementById(contentId);

      // Prepare mount point
      const $dialogParent = h('#organigram-dialog');
      const $mountPoint = h('#organigram-dialog-mountpoint');
      const $wrapper = h('div', $dialogParent, $mountPoint);
      document.body.appendChild($wrapper);

      // Render dialog
      ReactDOM.render(<Dialog closeButton={true} dialogParent={$dialogParent} onClose={() => {
        ReactDOM.unmountComponentAtNode($mountPoint);
        $wrapper.parentNode.removeChild($wrapper);
        $target.focus();
      }}>
        <div dangerouslySetInnerHTML={ { __html: $content.innerHTML } } />
      </Dialog>, $mountPoint);
    }
  }
});
