// @flow
import * as React from 'react';
import type { ImageT } from './Lightbox';
import { t } from '../../../javascripts/utils/withTranslation';

type Props = {
  currentImage: number,
  images: Array<ImageT>,
  setCurrentImage: number => any,
  numberOfThumbnails: number,
};

type State = {
  startIndex: number,
};

const getStartIndex = (
  currentImage: number,
  images: Array<ImageT>,
  numberOfThumbnails: number,
): number => {
  // first few images
  let startIndex: number = 0;

  const numberOfThumbnailsHalf = Math.floor(numberOfThumbnails / 2);

  if (currentImage >= images.length - numberOfThumbnailsHalf) {
    // Last few images
    startIndex = images.length - numberOfThumbnails;
  } else if (currentImage >= numberOfThumbnailsHalf) {
    // middle images
    startIndex = currentImage - numberOfThumbnailsHalf;
  }

  return startIndex;
};

export default class ThumbnailTrack extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    const startIndex = getStartIndex(
      this.props.currentImage,
      this.props.images,
      this.props.numberOfThumbnails,
    );

    this.state = {
      startIndex,
    };
  }

  static getDerivedStateFromProps(props: Props) {
    const startIndex = getStartIndex(props.currentImage, props.images, props.numberOfThumbnails);

    return { startIndex };
  }

  renderThumbnail = ({ src, caption, index }: ImageT) => (
    <button
      key={index}
      type="button"
      className="lightbox__thumbnail"
      onClick={() => this.props.setCurrentImage(index)}
      aria-label={t('Dieses Foto anzeigen')}
    >
      <img
        className={`lightbox__thumbnail-image ${
          index === this.props.currentImage ? 'lightbox__thumbnail-image--active' : ''
        }`}
        src={src}
        alt={caption}
      />
    </button>
  );

  getImageTrack = (): Array<ImageT> => {
    const { images, numberOfThumbnails } = this.props;
    const { startIndex } = this.state;

    return images.slice(startIndex, startIndex + numberOfThumbnails);
  };

  render() {
    const imageTrack = this.getImageTrack();

    return <div className="lightbox__thumbnail-track">{imageTrack.map(this.renderThumbnail)}</div>;
  }
}
