// @flow
import * as React from 'react';
import classnames from 'classnames';
import randomId from '../../../javascripts/utils/random-id';

type Props = {
  icon: string,
  title?: string,
  desc?: string,
  viewBox?: string,
  className?: string,
  id?: string,
  paths?: Array<string>,
};

// DOM generate Icon function
export function generateIcon({
  icon,
  title,
  desc,
  className,
  id,
  paths,
  viewBox = '0 0 200 200',
}: Props) {
  const labelledby = [];
  const xmlns = 'http://www.w3.org/2000/svg';

  // Get ID for icon
  const newId = id || randomId();

  // Generate icon
  const $icon = document.createElementNS(xmlns, 'svg');
  $icon.setAttributeNS(null, 'viewBox', viewBox);
  $icon.setAttributeNS(null, 'role', title ? 'img' : 'presentation');
  $icon.setAttributeNS(null, 'class', classnames('icon', `icon--${icon}`, className));
  $icon.setAttributeNS(null, 'id', `icon-${newId}`);

  // Add title
  if (title) {
    labelledby.push(`title-${newId}`);

    const $title = document.createElementNS(xmlns, 'title');
    $title.setAttributeNS(null, 'id', `title-${newId}`);
    $title.appendChild(document.createTextNode(title));
    $icon.appendChild($title);
  }

  // Description
  if (desc) {
    labelledby.push(`desc-${newId}`);

    const $desc = document.createElementNS(xmlns, 'desc');
    $desc.setAttributeNS(null, 'id', `desc-${newId}`);
    $desc.appendChild(document.createTextNode(desc));
    $icon.appendChild($desc);
  }

  // Add aria-labelledby to icon
  if (labelledby.length > 0) {
    $icon.setAttributeNS(null, 'aria-labelledby', labelledby.join(' '));
  }

  // Add reference to icons
  if (!paths) {
    const $use = document.createElementNS(xmlns, 'use');

    $use.setAttributeNS(
      'http://www.w3.org/1999/xlink',
      'xlink:href',
      `#icon-${icon}`,
    );

    $icon.appendChild($use);
    $icon.appendChild(document.createComment(' '));
  } else if (Array.isArray(paths)) {
    paths.forEach((path) => {
      const $path = document.createElementNS(xmlns, 'path');
      $path.setAttributeNS('', 'd', path);
      $icon.appendChild($path);
    });
  }

  return $icon;
}

// React component
const Icon = (props: Props) => {
  const {
    id, icon, title, desc, className, viewBox = '0 0 200 200', ...rest
  } = props;

  const classes = classnames(
    {
      icon: true,
      [`icon--${icon}`]: true,
    },
    className,
  );

  const titleId = title && `${id || randomId()}-title`;
  const descId = desc && `${id || randomId()}-desc`;
  const ariaLabelledBy = (titleId || descId) && [titleId, descId].join(' ');

  const attributes = {
    id,
    viewBox,
    'aria-labelledby': ariaLabelledBy,
    role: title ? 'img' : 'presentation',
  };

  return (
    <svg className={classes} {...attributes} {...rest}>
      {title && <title title={titleId}>{title}</title>}
      {desc && <desc title={descId}>{desc}</desc>}
      <use xlinkHref={`#icon-${icon}`} />
    </svg>
  );
};

export default Icon;
