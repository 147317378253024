// @flow
import * as React from 'react';
import classNames from 'classnames';

type Props = {
  src: string,
  alt: string,
  width?: number,
  height?: number,
  lazyload?: boolean,
  cover?: boolean,
  className?: string,
  decorative?: boolean,
};

const Image = (props: Props) => {
  const {
    cover, lazyload, width, height, alt, src, decorative, className,
  } = props;

  const classes = classNames(
    {
      image: true,
      'image--cover': cover,
      loading: lazyload,
    },
    className,
  );

  const style = !cover && width && height ? { paddingTop: `${(height / width) * 100}%` } : undefined;

  const imgClasses = classNames({
    image__img: true,
    'js-lazyload': lazyload,
  });

  const imgAttributes = {
    width,
    height,
    'data-src': lazyload ? src : null,
    role: decorative && 'presentation',
  };

  return (
    <div className={classes} style={style}>
      <img className={imgClasses} src={!lazyload && src} alt={alt} {...imgAttributes} />
    </div>
  );
};

export default Image;
