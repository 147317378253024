// @flow
import * as React from 'react';
import format from 'string-template';
import IconLink from '../../atoms/icon-link/IconLink';
import { t } from '../../../javascripts/utils/withTranslation';
import { TWITTER_SHARE_ACCOUNT } from '../../../javascripts/constants';

type SocialSharingOptions = {
  url: string,
  title: string,
  twitterAccount: string,
};

const services = [
  {
    name: 'Facebook',
    icon: 'facebook',
    link: 'https://www.facebook.com/sharer/sharer.php?u={url}',
    // eslint-disable-next-line
    onClick: () => { _etracker.sendEvent(new et_ClickEvent('Teilen', 'Facebook')); },
  },
  {
    name: 'x.com',
    icon: 'x.com',
    link: 'http://x.com/intent/tweet?text={title}+{url}&via={twitterAccount}',
    // eslint-disable-next-line
    onClick: () => { _etracker.sendEvent(new et_ClickEvent('Teilen', 'x.com')); },
  },
  {
    name: 'LinkedIn',
    icon: 'linkedin',
    link: 'https://www.linkedin.com/shareArticle?mini=true&url={url}',
    // eslint-disable-next-line
    onClick: () => { _etracker.sendEvent(new et_ClickEvent('Teilen', 'LinkedIn')); },
  },
  {
    name: 'Reddit',
    icon: 'reddit',
    link: 'http://www.reddit.com/submit?url={url}&title={title}',
    // eslint-disable-next-line
    onClick: () => { _etracker.sendEvent(new et_ClickEvent('Teilen', 'Reddit')); },
  },
  {
    name: 'Pinterest',
    icon: 'pinterest',
    link: 'https://pinterest.com/pin/create/button/?url={url}',
    // eslint-disable-next-line
    onClick: () => { _etracker.sendEvent(new et_ClickEvent('Teilen', 'Pinterest')); },
  },
  {
    name: 'WhatsApp',
    icon: 'whatsapp',
    link: 'https://wa.me/?text={title}+{url}',
    // eslint-disable-next-line
    onClick: () => { _etracker.sendEvent(new et_ClickEvent('Teilen', 'WhatsApp')); },
  },
  {
    name: 'XING',
    icon: 'xing',
    link: 'https://www.xing.com/spi/shares/new?url={url}',
    // eslint-disable-next-line
    onClick: () => { _etracker.sendEvent(new et_ClickEvent('Teilen', 'XING')); },
  },
];

const renderService = ({
  name, icon, link, onClick,
}, index) => (
  <li key={index} className="social-sharing__item">
    <IconLink
      className="js-popup"
      link={link}
      onClick={onClick}
      stacked
      icon={icon}
      attr={{
        'aria-label': t('Auf {name} teilen.', { name }),
        'data-name': name,
      }}
    >
      {name}
    </IconLink>
  </li>
);

const SocialSharing = ({
  url = window.location,
  title = document.title,
  twitterAccount = TWITTER_SHARE_ACCOUNT,
}: SocialSharingOptions) => (
  <div className="social-sharing">
    <ul className="social-sharing__list">
      {services
        .map(({ link, ...service }) => ({
          ...service,
          link: format(link, {
            url: encodeURIComponent(url),
            title: encodeURIComponent(title),
            twitterAccount: encodeURIComponent(twitterAccount),
          }),
        }))
        .map(renderService)}
    </ul>
  </div>
);

export default SocialSharing;
