import { MEDIA_QUERIES } from 'javascripts/constants';
import Toggle from './Toggle';
import NavigationMobile from './NavigationMobile';
import NavigationDesktop from './NavigationDesktop';

document.querySelectorAll('.simple-header__toggle').forEach(($toggle) => {
  const toggle = new Toggle($toggle);

  // Init media query
  const mql = window.matchMedia(MEDIA_QUERIES.l);

  // Listener
  const onMediaQueryChange = (mq) => {
    const isDesktop = mq.matches;

    if (isDesktop) {
      toggle.deinit();
    } else {
      toggle.init();
    }
  };

  // Listen on media query changes
  mql.addListener(onMediaQueryChange);

  // First run
  onMediaQueryChange(mql);
});

document.querySelectorAll('.simple-header__navigation').forEach(($navigation) => {
  const navigationMobile = new NavigationMobile($navigation);
  const navigationDesktop = new NavigationDesktop($navigation);

  // Init media query
  const mql = window.matchMedia(MEDIA_QUERIES.l);

  // Listener
  const onMediaQueryChange = (mq) => {
    const isDesktop = mq.matches;

    if (isDesktop) {
      navigationMobile.deinit();
      navigationDesktop.init();
    } else {
      navigationDesktop.deinit();
      navigationMobile.init();
    }
  };

  // Listen on media query changes
  mql.addListener(onMediaQueryChange);

  // First run
  onMediaQueryChange(mql);
});
