import h from 'hyperscript';
import { generateIcon as icon } from 'components/_particles/icon/Icon';
import Detabinator from 'javascripts/utils/detabinator';
import detectPrefixes from 'javascripts/utils/detect-prefixes';
import randomId from 'javascripts/utils/random-id';

let prefixes = null;

const truncatedContainer = ($truncatedContainer) => {
  // State
  const $content = $truncatedContainer.querySelector('.truncated-container__content');
  const $contentInner = $content.querySelector('.truncated-container__content-inner');

  // Helper
  const getHeightForContentInner = () => $contentInner.getBoundingClientRect().height;
  const isHidden = () => $truncatedContainer.offsetParent === null;

  // Check if element is visible
  if (isHidden()) {
    return;
  }

  // Check if script has already run
  if ($truncatedContainer.classList.contains('truncated-container--ready')) {
    return;
  }

  // Has run
  $truncatedContainer.classList.add('truncated-container--ready');

  // Check if current height is shorter than 400px
  if (getHeightForContentInner() <= 400) {
    $truncatedContainer.classList.add('truncated-container--to-short');
    return;
  }

  // Disable tabbing inside
  const detabinator = new Detabinator($contentInner, true);

  // Get ID for content
  const id = `truncated-container-${randomId()}`;

  // Set attributes on content
  $content.setAttribute('id', id);
  $content.setAttribute('aria-labelledby', `${id}-button`);

  // Get prefixes
  if (!prefixes) {
    prefixes = detectPrefixes();
  }

  // Add transitionEnd
  const transitionEnd = () => {
    this.style['max-height'] = 'none';
    this.removeEventListener(prefixes.transitionEnd, transitionEnd);
  };

  // Generate label
  const $label = h(
    'span.truncated-container__expand-button-label',
    'Inhalt komplett anzeigen',
  );

  // Add button
  const $button = h('.truncated-container__button', h(
    'button.truncated-container__expand-button',
    {
      type: 'button',
      id: `${id}-button`,
      attrs: {
        'aria-controls': id,
        'aria-expanded': 'false',
        'aria-label': 'Inhalt aufklappen bzw. schließen',
      },
      onclick() {
        // Get state of container
        const open = this.getAttribute('aria-expanded') === 'true';

        // Toggle button expanded attribute
        this.setAttribute('aria-expanded', open ? 'false' : 'true');

        // Toggle class
        $truncatedContainer.classList.toggle('truncated-container--open');

        // Toggle area
        if (!open) {
          $label.innerText = 'Inhalt verstecken';
          detabinator.inert = false;
          $content.addEventListener(prefixes.transitionEnd, transitionEnd);
          $content.style['max-height'] = `${getHeightForContentInner()}px`;
          $content.setAttribute('tabindex', '0');
          $content.focus();
        } else {
          $label.innerText = 'Inhalt komplett anzeigen';
          detabinator.inert = true;
          $content.style['max-height'] = '35rem';
          $content.removeAttribute('tabindex');
        }
      },
    },
    icon({ icon: 'hook-light-down', className: 'truncated-container__expand-button-icon' }),
    $label,
  ));

  // Add button
  $truncatedContainer.appendChild($button);
};

document.querySelectorAll('.truncated-container')
  .forEach($truncatedContainer => truncatedContainer($truncatedContainer));

export default truncatedContainer;
