import ReactDOM from 'react-dom';
import React from 'react';
import h from 'hyperscript';
import store from 'store/dist/store.modern';
import Dialog from 'components/_particles/dialog/Dialog';

const $condolenceDialog = document.querySelector('.condolence-dialog');

if ($condolenceDialog) {
  const lastCondolenceId = store.get('condolence-dialog-id');
  const { id } = $condolenceDialog.id;

  if (!id || lastCondolenceId !== id) {
    // Save id
    if (id) {
      store.set('condolence-dialog-id', id);
    }

    // Current focus
    const $activeElement = document.activeElement;

    // Prepare mount point
    const $dialogParent = h('#condolence-dialog');
    const $mountPoint = h('#condolence-dialog-mountpoint');
    const $wrapper = h('div', $dialogParent, $mountPoint);
    document.body.appendChild($wrapper);

    // Render dialog
    ReactDOM.render(<Dialog
      closeButton={true}
      dialogParent={$dialogParent}
      portalClass='condolence-dialog t-inverted'
      className='condolence-dialog__dialog'
      dialogScrollInnerClassName='condolence-dialog__inner'
      onClose={() => {
        ReactDOM.unmountComponentAtNode($mountPoint);
        $wrapper.parentNode.removeChild($wrapper);

        if ($activeElement) {
          $activeElement.focus();
        }
      }}
    >
      <div className='condolence-dialog__content' dangerouslySetInnerHTML={ { __html: $condolenceDialog.innerHTML } } />
    </Dialog>, $mountPoint);
  }
}
