import Wallop from 'wallop';

export default class ChangeBreaker {
  constructor($el) {
    this.$el = $el;
    this.$playToggle = this.$el.querySelector('.change-breaker__button--toggle');

    this.wallop = new Wallop(this.$el, {
      itemClass: 'change-breaker__slide',
      currentItemClass: 'change-breaker__slide--current',
      showPreviousClass: 'change-breaker__slide--show-prev',
      showNextClass: 'change-breaker__slide--show-next',
      hidePreviousClass: 'change-breaker__slide--hide-prev',
      hideNextClass: 'change-breaker__slide--hide-next',
      buttonPreviousClass: 'change-breaker__button--prev',
      buttonNextClass: 'change-breaker__button--next',
    });

    this.initAutoplay();
  }

  isPlaying() {
    return (this.$el.getAttribute('data-playing') === 'true');
  }

  isAutoplay() {
    return (this.$el.getAttribute('data-autoplay') === 'true');
  }

  initAutoplay() {
    // Touch kill
    let hasTouched = false;

    // Init timer
    const timerInit = () => setInterval(() => this.wallop.next(), 10000);
    let timer = this.isPlaying() ? timerInit() : false;

    // Kill timer if a mouse enters the slider
    this.$el.addEventListener('mouseover', () => {
      // this.$el.setAttribute('data-playing', 'false');
      clearInterval(timer);
    });

    // Restart timer if a mouse leaves the slider
    this.$el.addEventListener('mouseleave', () => {
      if (!hasTouched && this.isPlaying()) timer = timerInit();
    });

    // Kill the timer if at least on touch interaction happend
    this.$el.addEventListener('touchstart', function killOnTouchStart() {
      clearInterval(timer);
      this.$el.revomeEventListener('touchstart', killOnTouchStart);
      hasTouched = true;
    }, { passive: true });

    // Autoplay toggle button (play/pause)
    this.$playToggle.addEventListener('click', () => {
      if (this.isPlaying()) {
        this.$el.setAttribute('data-playing', 'false');
        clearInterval(timer);
      } else {
        this.$el.setAttribute('data-playing', 'true');
        if (!hasTouched) timer = timerInit();
        setTimeout(() => this.wallop.next(), 500);
      }
    });
  }
}

document.querySelectorAll('.change-breaker').forEach($slider => new ChangeBreaker($slider));
